import { useMemo } from 'react';
import moment from 'moment';
import { some } from 'lodash';
import { uniqBy } from 'lodash';
import { getDownloadsPending, tableUpdateSettings } from '../actions/AppActions';
import { DATE_FORMAT } from '../constants';
import eBookIcon from '../../../img/sprite/ebook.svg';
import videoIcon from '../../../img/sprite/video.svg';
import downloadIcon from '../../../img/sprite/cloud-download.svg';
import { resourceRedirectQuery } from './pathHelpers';
import { isExpiredMedia } from './tableCellHelpers';

export function composeContent(data) {
  const { FILE_DOWNLOAD, EBOOK, LIFEWAY_APPLICATION, RENTAL, SIMULCAST, MEMBERSHIP } = data?.reduce((acc, cur) => ({
    ...acc,
    [cur?.distributionAction]: [
      ...(acc[cur?.distributionAction] || []),
      cur,
    ],
  }), {}) || {};
  return ({
    downloads: composeCollection(FILE_DOWNLOAD),
    ebooks: composeCollection(EBOOK),
    otherContent: composeCollection(LIFEWAY_APPLICATION),
    videos: composeCollection(RENTAL),
    simulcasts: composeCollection(SIMULCAST),
    memberships: composeCollection(MEMBERSHIP)
  });
}

export const addOrderImages = (data, itemsList) => itemsList.map(item => ({ ...item, imageUrl: data.find(d => item.itemId === d.itemNumber)?.orderedImageLocations?.[0]?.imageLocation?.url }));

export const addOrderHistoryImages = (data, rows) => {
  const imageData = data;
  const updatedRows = rows?.map(row => {
    const rowId = row?._id;
    if (imageData) {
      const match = imageData?.find(image => image?.id === rowId);
      if (match) {
        const images = match?.rows?.map(item => item?.orderedImageLocations[0]?.imageLocation?.url);
        return { ...row, orderImage: images };
      }
    }
    return row;
  })
  return updatedRows;
};


export const addDigitalMediaImages = (data, itemsList) => itemsList.map(item => ({ ...item, imageUrl: data.find(d => item.itemNumber === d.itemNumber)?.orderedImageLocations?.[0]?.imageLocation?.url }));

export const addSimulcastImages = (data, itemsList) => itemsList.map(item => ({ ...item, item: { ...item.item, imageUrl: data.find(d => item.item.number === d.itemNumber)?.orderedImageLocations?.[0]?.imageLocation?.url } }));

export function composeCollection(contentArray = []) {
  return tailorForTable(mapDatesToHumanReadable(contentArray));
}

export function tailorForTable(array = []) {
  return { rows: array, totalRows: array.length };
}

export function epochToHumanReadable(epoch) {
  if (!epoch) return null;

  return moment.utc(epoch).local().format(DATE_FORMAT);
}

export function mapDatesToHumanReadable(array) {
  return array.map(obj => (
    {
      ...obj,
      startDate: epochToHumanReadable(obj.startDate),
      endDate: epochToHumanReadable(obj.endDate)
    }
  ));
}

export function dispatchMediaTableSettingsForEach(dispatch, settings) {
  mediaTableNames().forEach(tableName => {
    dispatch(tableUpdateSettings({ tableName, settings }));
  });
}

export function setTablesIsLoading(dispatch, isLoading) {
  dispatchMediaTableSettingsForEach(dispatch, { isLoading });
}

export function hasDataPresent(digitalMedia) {
  return some(digitalMedia, (table) => !!table.totalRows);
}

export function getOrderNumberByItemNumber(itemNumber, digitalMedia) {
  let orderNumber;
  const keys = Object.keys(digitalMedia);
  let i = 0;
  while (!orderNumber && i < keys.length) {
    const rows = digitalMedia[keys[i]].rows;
    if (rows) {
      const row = rows.find(r => r.itemNumber === itemNumber);
      if (row && row.orderNumber) orderNumber = row.orderNumber;
    }
    i += 1;
  }
  return orderNumber;
}

export function getOrderNumbersFromQuery(query, digitalMedia) {
  let q = '';
  if (!query || !digitalMedia) return q;
  const items = query.split(' ');
  const orders = [];

  items.forEach(k => {
    const orderNumber = getOrderNumberByItemNumber(k, digitalMedia);
    if (orderNumber && orders.indexOf(orderNumber) < 0) orders.push(orderNumber);
  });

  q = orders.length && orders.join(' ') || query;
  return q;
}

export function mediaTableNames() {
  return [
    'downloads',
    'otherContent',
    'videos',
    'simulcasts',
    'memberships',
    'ebooks'
  ];
}

export function getMediaTypeConfigs(digitalMedia) {
  const openResource = () => {
    window.open(resourceRedirectQuery(digitalMedia?.item?.number || digitalMedia?.itemNumber));
  };

  const createMediaTypeConfig = (type, icon, buttonText, handleClick) => ({
    type,
    icon,
    buttonText,
    onHandleClick: handleClick || openResource
  });

  const mediaTypes = {
    'EBOOK': createMediaTypeConfig('eBook', eBookIcon, 'Read eBook'),
    'SIMULCAST': createMediaTypeConfig('Simulcast', videoIcon, 'Watch Simulcast'),
    'RENTAL': createMediaTypeConfig('Video', videoIcon, 'Watch Video'),
    'FILE_DOWNLOAD': createMediaTypeConfig('Download', downloadIcon, 'Download')
  };

  return mediaTypes[digitalMedia?.item?.distributionAction || digitalMedia?.distributionAction];
}

/* Set up for later work */
export const isMediaLessThanXDays = (media, days) => {
  var mediaCreatedDate = new Date(media.createdAt).getTime();
  var today = new Date().getTime();
  var differenceinTime = mediaCreatedDate - today;
  var differenceInDays = Math.round(differenceinTime / (1000 * 3600 * 24));
  const isMediaLessThanXDays = differenceInDays <= days &&differenceInDays >= 0;
  return isMediaLessThanXDays;
};

export const sortDigitalMedia = (...digitalMedia) => {
  return digitalMedia.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
};

export const sortMedia = (media) => {
  const sortItems = (a, b) => {
    const createdAtA = a?.createdAt ? new Date(a.createdAt).getTime() : Infinity;
    const seatedAtA = a?.seatedAt ? new Date(a.seatedAt).getTime() : Infinity;
    const earliestDateA = Math.min(createdAtA, seatedAtA);

    const createdAtB = b?.createdAt ? new Date(b.createdAt).getTime() : Infinity;
    const seatedAtB = b?.seatedAt ? new Date(b.seatedAt).getTime() : Infinity;
    const earliestDateB = Math.min(createdAtB, seatedAtB);

    if (earliestDateA < earliestDateB) return 1;
    if (earliestDateA > earliestDateB) return -1;

    return 0;
  };
  
  media.sort(sortItems);

  return media;
};

export function extractLicenseId(arr) {
  return arr.map(item => {
    const { license, ...rest } = item;
    return {
      ...rest,
      id: license?.id,
      license: {
        ...license,
      }
    };
  });
}

export const isMediaAvailable = (digitalMedia) => {
  const startDate = new Date(digitalMedia?.startDate).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const isMediaAvailable = startDate <= today;
  return isMediaAvailable;
};

export function formatDigitalMediaDashboard(licenses = [], mediaContent = []) {
  const moduleTypes = [
    'EBOOK',
    'SIMULCAST',
    'RENTAL',
    'FILE_DOWNLOAD'
  ];

  const formattedMedia = mediaContent ? extractLicenseId(mediaContent) : [];

  // Filter media by module types
  const filterMediaByType = (mediaItems) =>
    mediaItems?.filter((media) =>
      moduleTypes.includes(media?.item?.distributionAction || media?.distributionAction)
    );

  // Combine and deduplicate media entries, and filter out expired media
  const combinedMedia = useMemo(() => {
    const allMedia = [].concat(...licenses, ...formattedMedia);
    const uniqueMedia = filterMediaByType(sortMedia(uniqBy(allMedia, 'id'))).flat();
    return uniqueMedia?.filter((media) => !isExpiredMedia(media));
  }, [licenses, formattedMedia]);

  return combinedMedia;
}

export const findDigitalMediaImage = (images, itemNumber) => {
  return images?.find(img => itemNumber === img?.itemNumber)?.orderedImageLocations?.[0]?.imageLocation?.url;
};
