import * as Constants from '../constants';

const error = true;

export function onInit(payload) {
  return {
    type: Constants.APP_ON_INIT,
    payload
  };
}

export function onResize() {
  return {
    type: Constants.APP_ON_RESIZE
  };
}

export function onError(payload) {
  return {
    type: Constants.APP_ON_ERROR,
    payload
  };
}

export function onUpdateBreakPoints(payload) {
  return {
    type: Constants.APP_UPDATE_BREAKPOINTS,
    payload
  };
}

export function toggleNavPanel(panelName, open) {
  return {
    type: Constants.APP_NAV_TOGGLE,
    payload: {
      panelName,
      open
    }
  };
}

export function toggleForm(payload) {
  return {
    type: Constants.APP_TOGGLE_FORM,
    payload
  };
}

export function toggleModal() {
  return {
    type: Constants.APP_TOGGLE_MODAL
  };
}

export function triggerModal(payload) {
  return {
    type: Constants.APP_TRIGGER_MODAL,
    payload
  };
}

export function confirmModal(payload) {
  return {
    type: Constants.APP_CONFIRM_MODAL,
    payload
  };
}

export function cancelModal(payload) {
  return {
    type: Constants.APP_CANCEL_MODAL,
    payload
  };
}

export function navigateAway(payload) {
  return {
    type: Constants.APP_NAVIGATE_AWAY,
    payload
  };
}

export function homeDataPending(payload) {
  return {
    type: Constants.HOME_DATA_PENDING,
    payload
  };
}

export function homeDataSuccess(payload) {
  return {
    type: Constants.HOME_DATA_SUCCESS,
    payload
  };
}

export function homeDataError(payload) {
  return {
    type: Constants.HOME_DATA_ERROR,
    payload,
    error
  };
}

export function setPageTitles(payload) {
  return {
    type: Constants.PLACEHOLDER_SET_PAGE_TITLES,
    payload
  };
}

export function addPhonePending(payload) {
  return {
    type: Constants.PROFILE_ADD_PHONE_PENDING,
    payload
  };
}

export function addPhoneSuccess(payload) {
  return {
    type: Constants.PROFILE_ADD_PHONE_SUCCESS,
    payload
  };
}

export function addPhoneError(payload) {
  return {
    type: Constants.PROFILE_ADD_PHONE_ERROR,
    payload,
    error
  };
}

export function updatePhonePending(payload) {
  return {
    type: Constants.PROFILE_UPDATE_PHONE_PENDING,
    payload
  };
}

export function updatePhoneSuccess(payload) {
  return {
    type: Constants.PROFILE_UPDATE_PHONE_SUCCESS,
    payload
  };
}

export function updatePhoneError(payload) {
  return {
    type: Constants.PROFILE_UPDATE_PHONE_ERROR,
    payload,
    error
  };
}

export function deletePhoneConfirm(payload) {
  return {
    type: Constants.PROFILE_DELETE_PHONE_CONFIRM,
    payload
  };
}

export function deletePhonePending(payload) {
  return {
    type: Constants.PROFILE_DELETE_PHONE_PENDING,
    payload
  };
}

export function deletePhoneSuccess(payload) {
  return {
    type: Constants.PROFILE_DELETE_PHONE_SUCCESS,
    payload
  };
}

export function deletePhoneError(payload) {
  return {
    type: Constants.PROFILE_DELETE_PHONE_ERROR,
    payload,
    error
  };
}

export function getPhonePending(payload) {
  return {
    type: Constants.PROFILE_GET_PHONE_PENDING,
    payload
  };
}

export function getPhoneSuccess(payload) {
  return {
    type: Constants.PROFILE_GET_PHONE_SUCCESS,
    payload
  };
}

export function getPhoneError(payload) {
  return {
    type: Constants.PROFILE_GET_PHONE_ERROR,
    payload,
    error
  };
}

export function toggleProcessedItem(payload) {
  return {
    type: Constants.TOGGLE_PROCESSED_ITEM,
    payload
  };
}

export function setSelectedProfile(payload) {
  return {
    type: Constants.SET_SELECTED_PROFILE,
    payload
  };
}

export function logOutPending() {
  return { type: Constants.APP_LOG_OUT_PENDING };
}

export function logOutSuccess() {
  return { type: Constants.APP_LOG_OUT_SUCCESS };
}

export function logOutError(payload) {
  return {
    type: Constants.APP_LOG_OUT_ERROR,
    payload,
    error
  };
}

export function reauthenticatePending() {
  return {
    type: Constants.APP_REAUTHENTICATE_PENDING
  };
}

export function loginPending(payload) {
  return {
    type: Constants.APP_LOG_IN_PENDING,
    payload
  };
}

export function loginSuccess(payload) {
  return {
    type: Constants.APP_LOG_IN_SUCCESS,
    payload
  };
}

export function lifeWayAccountLogout() {
  return {
    type: Constants.LIFEWAYACCOUNT_LOGOUT
  };
}

export function lifeWayAccountLogin() {
  return {
    type: Constants.LIFEWAYACCOUNT_LOGIN
  };
}

export function setSsoReadyState() {
  return {
    type: Constants.SSO_READY_STATE
  };
}

export function setIsImpersonationSession(isImpersonationSession) {
  return {
    type: Constants.SET_IS_IMPERSONATION_SESSION,
    payload: {
      isImpersonationSession
    }
  };
}

export function isLoggedInToFalse() {
  return { type: Constants.LOGGED_IN_TO_FALSE };
}

export function isLoggedInToTrue() {
  return { type: Constants.LOGGED_IN_TO_TRUE };
}

export function fetchUserDataPending() {
  return {
    type: Constants.FETCH_USER_DATA_PENDING
  };
}

export function fetchUserDataSuccess(payload) {
  return {
    type: Constants.FETCH_USER_DATA_SUCCESS,
    payload
  };
}

export function fetchUserDataError(payload) {
  return {
    type: Constants.FETCH_USER_DATA_ERROR,
    payload,
    error
  };
}

export function postPersonalInfoPending(payload) {
  return {
    type: Constants.POST_PERSONAL_INFO_PENDING,
    payload
  };
}

export function postPersonalInfoSuccess(payload) {
  return {
    type: Constants.POST_PERSONAL_INFO_SUCCESS,
    payload
  };
}

export function postPersonalInfoError(payload) {
  return {
    type: Constants.POST_PERSONAL_INFO_ERROR,
    payload,
    error
  };
}

export function getDefaultBillingAccountIdPending(payload) {
  return {
    type: Constants.GET_DEFAULT_BILLING_ACCOUNT_ID_PENDING,
    payload
  };
}

export function getDefaultBillingAccountIdSuccess(payload) {
  return {
    type: Constants.GET_DEFAULT_BILLING_ACCOUNT_ID_SUCCESS,
    payload
  };
}

export function getDefaultBillingAccountIdError(payload) {
  return {
    type: Constants.GET_DEFAULT_BILLING_ACCOUNT_ID_ERROR,
    payload
  };
}

export function getShippingAddressesPending() {
  return {
    type: Constants.GET_SHIPPING_ADDRESSES_PENDING
  };
}

export function getShippingAddressesSuccess(payload) {
  return {
    type: Constants.GET_SHIPPING_ADDRESSES_SUCCESS,
    payload
  };
}

export function getShippingAddressesError(payload) {
  return {
    type: Constants.GET_SHIPPING_ADDRESSES_ERROR,
    payload,
    error
  };
}

export function addUserShippingAddressPending(payload) {
  return {
    type: Constants.ADD_USER_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function addUserShippingAddressSuccess(payload) {
  return {
    type: Constants.ADD_USER_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function addUserShippingAddressError(payload) {
  return {
    type: Constants.ADD_USER_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function addAccountShippingAddressPending(payload) {
  return {
    type: Constants.ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function addAccountShippingAddressSuccess(payload) {
  return {
    type: Constants.ADD_ACCOUNT_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function addAccountShippingAddressError(payload) {
  return {
    type: Constants.ADD_ACCOUNT_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function updateAccountPending(payload) {
  return {
    type: Constants.PROFILE_UPDATE_ACCOUNT_PENDING,
    payload
  };
}

export function updateEmailPending(payload) {
  return {
    type: Constants.PROFILE_UPDATE_EMAIL_PENDING,
    payload
  };
}

export function updateEmailSuccess(payload) {
  return {
    type: Constants.PROFILE_UPDATE_EMAIL_SUCCESS,
    payload
  };
}

export function updateEmailError(payload) {
  return {
    type: Constants.PROFILE_UPDATE_EMAIL_ERROR,
    payload,
    error
  };
}

export function updatePasswordPending(payload) {
  return {
    type: Constants.PROFILE_UPDATE_PASSWORD_PENDING,
    payload
  };
}

export function updatePasswordSuccess() {
  return {
    type: Constants.PROFILE_UPDATE_PASSWORD_SUCCESS
  };
}

export function updatePasswordError(payload) {
  return {
    type: Constants.PROFILE_UPDATE_PASSWORD_ERROR,
    payload,
    error
  };
}

export function getVODDevicesPending() {
  return {
    type: Constants.GET_VOD_DEVICES_PENDING
  };
}

export function getVODDevicesSuccess(payload) {
  return {
    type: Constants.GET_VOD_DEVICES_SUCCESS,
    payload
  };
}

export function getVODDevicesError(payload) {
  return {
    type: Constants.GET_VOD_DEVICES_ERROR,
    payload,
    error
  };
}

export function getWebAuthnPublicKeysPending() {
  return {
    type: Constants.GET_WEBAUTHN_PUBLIC_KEYS_PENDING
  };
}

export function getWebAuthnPublicKeysSuccess(payload) {
  return {
    type: Constants.GET_WEBAUTHN_PUBLIC_KEYS_SUCCESS,
    payload
  };
}

export function getWebAuthnPublicKeysError(payload) {
  return {
    type: Constants.GET_WEBAUTHN_PUBLIC_KEYS_ERROR,
    payload,
    error
  };
}

export function renameWebAuthnPublicKeyPending(payload) {
  return {
    type: Constants.RENAME_WEBAUTHN_PUBLIC_KEY_PENDING,
    payload
  };
}

export function renameWebAuthnPublicKeySuccess(payload) {
  return {
    type: Constants.RENAME_WEBAUTHN_PUBLIC_KEY_SUCCESS,
    payload
  };
}

export function renameWebAuthnPublicKeyError(payload) {
  return {
    type: Constants.RENAME_WEBAUTHN_PUBLIC_KEY_ERROR,
    payload,
    error
  };
}

export function renameVODDevicePending(
  { deviceCode, deviceName },
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.RENAME_VOD_DEVICE_PENDING,
    payload: {
      deviceCode,
      deviceName
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function renameVODDeviceSuccess(payload) {
  return {
    type: Constants.RENAME_VOD_DEVICE_SUCCESS,
    payload
  };
}

export function renameVODDeviceError(payload) {
  return {
    type: Constants.RENAME_VOD_DEVICE_ERROR,
    payload,
    error
  };
}

export function removeVODDeviceConfirm({ deviceName, deviceCode }, meta = {}) {
  return {
    type: Constants.REMOVE_DEVICE_CONFIRM,
    payload: {
      deviceCode,
      deviceName
    },
    meta
  };
}

export function removeWebAuthnPublicKeyConfirm(payload) {
  return {
    type: Constants.REMOVE_WEBAUTHN_PUBLIC_KEY_CONFIRM,
    payload
  };
}

export function removeWebAuthnPublicKeyPending(payload) {
  return {
    type: Constants.REMOVE_WEBAUTHN_PUBLIC_KEY_PENDING,
    payload
  };
}

export function removeWebAuthnPublicKeySuccess(payload) {
  return {
    type: Constants.REMOVE_WEBAUTHN_PUBLIC_KEY_SUCCESS,
    payload
  };
}

export function removeWebAuthnPublicKeyError(payload) {
  return {
    type: Constants.REMOVE_WEBAUTHN_PUBLIC_KEY_ERROR,
    payload,
    error
  };
}

export function removeVODDevicePending({ deviceCode }, meta = {}) {
  return {
    type: Constants.REMOVE_VOD_DEVICE_PENDING,
    payload: {
      deviceCode
    },
    meta
  };
}

export function removeVODDeviceSuccess({ deviceCode }) {
  return {
    type: Constants.REMOVE_VOD_DEVICE_SUCCESS,
    payload: {
      deviceCode
    }
  };
}

export function removeVODDeviceError(payload) {
  return {
    type: Constants.REMOVE_VOD_DEVICE_ERROR,
    payload,
    error
  };
}

export function setProductImagesLoading(payload) {
  return {
    type: Constants.SET_PRODUCT_IMAGES_LOADING,
    payload
  }
}

export function getProductsPending(payload) {
  return {
    type: Constants.GET_PRODUCTS_PENDING,
    payload
  };
}

export function getProductsSuccess(payload) {
  return {
    type: Constants.GET_PRODUCTS_SUCCESS,
    payload
  };
}

export function getProductsError(payload) {
  return {
    type: Constants.GET_PRODUCTS_ERROR,
    payload,
    error
  };
}

export function findProductsReplacementsPending(items) {
  return {
    type: Constants.FIND_PRODUCTS_REPLACEMENTS_PENDING,
    payload: {
      items
    }
  };
}

export function findProductsReplacementsSuccess(products) {
  return {
    type: Constants.FIND_PRODUCTS_REPLACEMENTS_SUCCESS,
    payload: {
      products
    }
  };
}

export function findProductsReplacementsError(payload) {
  return {
    type: Constants.FIND_PRODUCTS_REPLACEMENTS_ERROR,
    payload,
    error
  };
}

export function fetchProductImageURLs(payload) {
  return {
    type: Constants.FETCH_PRODUCT_IMAGE_URLS,
    payload
  };
}

export function fetchProductImageURLsSuccess(images) {
  return {
    type: Constants.FETCH_PRODUCT_IMAGE_URLS_SUCCESS,
    payload: {
      images
    }
  };
}

export function fetchProductImageURLsError(payload) {
  return {
    type: Constants.FETCH_PRODUCT_IMAGE_URLS_ERROR,
    payload,
    error
  };
}

export function queryMediaLocationsPending(payload) {
  return {
    type: Constants.QUERY_MEDIA_LOCATIONS_PENDING,
    payload
  };
}

export function queryMediaLocationsSuccess(payload) {
  return {
    type: Constants.QUERY_MEDIA_LOCATIONS_SUCCESS,
    payload
  };
}

export function queryMediaLocationsError(payload) {
  return {
    type: Constants.QUERY_MEDIA_LOCATIONS_ERROR,
    payload,
    error
  };
}

export function setBannerState(payload) {
  return {
    type: Constants.APP_SET_BANNER_STATE,
    payload
  };
}

export function clearBannerState() {
  return { type: Constants.APP_CLEAR_BANNER_STATE };
}

export function deleteUserShippingAddressConfirm(payload) {
  return {
    type: Constants.DELETE_USER_SHIPPING_ADDRESS_CONFIRM,
    payload
  };
}

export function deleteUserShippingAddressPending(payload) {
  return {
    type: Constants.DELETE_USER_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function deleteUserShippingAddressSuccess(payload) {
  return {
    type: Constants.DELETE_USER_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function deleteUserShippingAddressError(payload) {
  return {
    type: Constants.DELETE_USER_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function updateUserShippingAddressPending(payload) {
  return {
    type: Constants.UPDATE_USER_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function updateUserShippingAddressSuccess(payload) {
  return {
    type: Constants.UPDATE_USER_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function updateUserShippingAddressError(payload) {
  return {
    type: Constants.UPDATE_USER_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function updateAccountShippingAddressPending(payload) {
  return {
    type: Constants.UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function updateAccountShippingAddressSuccess(payload) {
  return {
    type: Constants.UPDATE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function updateAccountShippingAddressError(payload) {
  return {
    type: Constants.UPDATE_ACCOUNT_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function deleteAccountShippingAddressConfirm(payload) {
  return {
    type: Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM,
    payload
  };
}

export function deleteAccountShippingAddressPending(payload) {
  return {
    type: Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
    payload
  };
}

export function deleteAccountShippingAddressSuccess(payload) {
  return {
    type: Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function deleteAccountShippingAddressError(payload) {
  return {
    type: Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_ERROR,
    payload,
    error
  };
}

export function addAddressBeingProcessed(payload) {
  return {
    type: Constants.ADD_ADDRESS_BEING_PROCESSED,
    payload
  };
}

export function removeAddressBeingProcessed(payload) {
  return {
    type: Constants.REMOVE_ADDRESS_BEING_PROCESSED,
    payload
  };
}

export function getCountriesPending(payload) {
  return {
    type: Constants.GET_COUNTRIES_PENDING,
    payload
  };
}

export function getCountriesSuccess(payload) {
  return {
    type: Constants.GET_COUNTRIES_SUCCESS,
    payload
  };
}

export function getCountriesError(payload) {
  return {
    type: Constants.GET_COUNTRIES_ERROR,
    payload,
    error
  };
}

export function getStatesPending(payload) {
  return {
    type: Constants.GET_STATES_PENDING,
    payload
  };
}

export function getStatesSuccess(payload) {
  return {
    type: Constants.GET_STATES_SUCCESS,
    payload
  };
}

export function getStatesError(payload) {
  return {
    type: Constants.GET_STATES_ERROR,
    payload,
    error
  };
}

export function getMilitaryLocationsPending(payload) {
  return {
    type: Constants.GET_MILITARY_LOCATIONS_PENDING,
    payload
  };
}

export function getMilitaryLocationsSuccess(payload) {
  return {
    type: Constants.GET_MILITARY_LOCATIONS_SUCCESS,
    payload
  };
}

export function getMilitaryLocationsError(payload) {
  return {
    type: Constants.GET_MILITARY_LOCATIONS_ERROR,
    payload,
    error
  };
}

export function setAddressSuggestions(payload) {
  return {
    type: Constants.SET_ADDRESS_SUGGESTIONS,
    payload
  };
}

// Payment Method Actions

export function assignDefaultPaymentMethodPending(payload) {
  return {
    type: Constants.ASSIGN_DEFAULT_PAYMENT_METHOD_PENDING,
    payload
  };
}

export function assignDefaultPaymentMethodSuccess(payload) {
  return {
    type: Constants.ASSIGN_DEFAULT_PAYMENT_METHOD_SUCCESS,
    payload
  };
}

export function assignDefaultPaymentMethodError(payload) {
  return {
    type: Constants.ASSIGN_DEFAULT_PAYMENT_METHOD_ERROR,
    payload,
    error
  };
}

export function getPaymentMethodsPending({ onSuccess, onError } = {}) {
  return {
    type: Constants.GET_PAYMENT_METHODS_PENDING,
    meta: {
      onSuccess,
      onError
    }
  };
}

export function getPaymentMethodsSuccess(
  creditCards,
  paypal,
  defaultPaymentMethod
) {
  return {
    type: Constants.GET_PAYMENT_METHODS_SUCCESS,
    payload: {
      creditCards,
      paypal,
      defaultPaymentMethod
    }
  };
}

export function getPaymentMethodsError(payload) {
  return {
    type: Constants.GET_PAYMENT_METHODS_ERROR,
    payload,
    error
  };
}

export function addCreditCardPending(payload) {
  return {
    type: Constants.ADD_CREDIT_CARD_PENDING,
    payload
  };
}

export function addCreditCardSuccess(payload) {
  return {
    type: Constants.ADD_CREDIT_CARD_SUCCESS,
    payload
  };
}

export function addCreditCardError(payload) {
  return {
    type: Constants.ADD_CREDIT_CARD_ERROR,
    payload
  };
}

export function updateCreditCardPending(payload) {
  return {
    type: Constants.UPDATE_CREDIT_CARD_PENDING,
    payload
  };
}

export function updateCreditCardSuccess(payload) {
  return {
    type: Constants.UPDATE_CREDIT_CARD_SUCCESS,
    payload
  };
}

export function updateCreditCardError(payload) {
  return {
    type: Constants.UPDATE_CREDIT_CARD_ERROR,
    payload
  };
}

export function deleteCreditCardConfirm(payload) {
  return {
    type: Constants.DELETE_CREDIT_CARD_CONFIRM,
    payload
  };
}

export function deleteCreditCardPending(payload) {
  return {
    type: Constants.DELETE_CREDIT_CARD_PENDING,
    payload
  };
}

export function deleteCreditCardSuccess(payload) {
  return {
    type: Constants.DELETE_CREDIT_CARD_SUCCESS,
    payload
  };
}

export function deleteCreditCardError(payload) {
  return {
    type: Constants.DELETE_CREDIT_CARD_ERROR,
    payload
  };
}

export function deletePayPalAccountConfirm(payload) {
  return {
    type: Constants.DELETE_PAYPAL_ACCOUNT_CONFIRM,
    payload
  };
}

export function deletePayPalAccountPending(payload) {
  return {
    type: Constants.DELETE_PAYPAL_ACCOUNT_PENDING,
    payload
  };
}

export function deletePayPalAccountSuccess(payload) {
  return {
    type: Constants.DELETE_PAYPAL_ACCOUNT_SUCCESS,
    payload
  };
}

export function deletePayPalAccountError(payload) {
  return {
    type: Constants.DELETE_PAYPAL_ACCOUNT_ERROR,
    payload
  };
}

export function getPaymentTokenPending(payload) {
  return {
    type: Constants.GET_PAYMENT_TOKEN_PENDING,
    payload
  };
}

export function getPaymentTokenSuccess(payload) {
  return {
    type: Constants.GET_PAYMENT_TOKEN_SUCCESS,
    payload
  };
}

export function addBillingAcctAssocPending(payload) {
  return {
    type: Constants.ADD_BILLING_ACCT_ASSOC_PENDING,
    payload
  };
}

export function addBillingAcctAssocSuccess(payload) {
  return {
    type: Constants.ADD_BILLING_ACCT_ASSOC_SUCCESS,
    payload
  };
}

export function addBillingAcctAssocError(payload) {
  return {
    type: Constants.ADD_BILLING_ACCT_ASSOC_ERROR,
    payload
  };
}

export function getBillingAccsPending() {
  return { type: Constants.GET_BILLING_ACCS_PENDING };
}

export function getBillingAccsSuccess(payload) {
  return {
    type: Constants.GET_BILLING_ACCS_SUCCESS,
    payload
  };
}

export function getBillingAccsError(payload) {
  return {
    type: Constants.GET_BILLING_ACCS_ERROR,
    payload
  };
}

export function getLinkedOrganizationsPending(userId) {
  return {
    type: Constants.GET_LINKED_ORGS_PENDING,
    payload: {
      userId
    }
  };
}

export function getLinkedOrganizationsSuccess(payload) {
  return {
    type: Constants.GET_LINKED_ORGS_SUCCESS,
    payload
  };
}

export function getLinkedOrganizationsError(error) {
  return {
    type: Constants.GET_LINKED_ORGS_ERROR,
    error
  };
}

export function getLinkedOrganizationGroupsPending(organizationId) {
  return {
    type: Constants.GET_LINKED_ORG_GROUPS_PENDING,
    payload: {
      organizationId
    }
  };
}

export function getLinkedOrganizationGroupsSuccess(payload) {
  return {
    type: Constants.GET_LINKED_ORG_GROUPS_SUCCESS,
    payload
  };
}

export function getLinkedOrganizationGroupsError(error) {
  return {
    type: Constants.GET_LINKED_ORG_GROUPS_ERROR,
    error
  };
}

export function getUserRolesPending(payload) {
  return {
      type: Constants.GET_USER_ROLES_PENDING,
      payload
  }
}

export function getUserRolesSuccess(payload) {
  return {
      type: Constants.GET_USER_ROLES_SUCCESS,
      payload
  }
}

export function getUserRolesError(error) {
  return {
      type: Constants.GET_USER_ROLES_ERROR,
      error
  }
}

export function getOrganizationMemberRolesPending(payload) {
    return {
        type: Constants.GET_ORGANIZATION_MEMBER_ROLES_PENDING,
        payload
    }
}

export function getOrganizationMemberRolesSuccess(payload) {
    return {
        type: Constants.GET_ORGANIZATION_MEMBER_ROLES_SUCCESS,
        payload
    }
}

export function getOrganizationMemberRolesError(error) {
    return {
        type: Constants.GET_ORGANIZATION_MEMBER_ROLES_ERROR,
        error
    }
}

export function getCustomerPermissionsPending(payload) {
    return {
        type: Constants.GET_CUSTOMER_PERMISSIONS_PENDING,
        payload
    }
}

export function getCustomerPermissionsSuccess(payload) {
    return {
        type: Constants.GET_CUSTOMER_PERMISSIONS_SUCCESS,
        payload
    }
}

export function getCustomerPermissionsError(error) {
    return {
        type: Constants.GET_CUSTOMER_PERMISSIONS_ERROR,
        error
    }
}

export function unlinkOrganizationConfirm(payload, callbackFn) {
  return {
    type: Constants.UNLINK_ORG_CONFIRM,
    payload,
    callbackFn
  };
}

export function unlinkOrganizationPending(organizationId, callbackFn) {
  return {
    type: Constants.UNLINK_ORG_PENDING,
    payload: { organizationId },
    callbackFn
  };
}

export function unlinkOrganizationSuccess(organizationId) {
  return {
    type: Constants.UNLINK_ORG_SUCCESS,
    payload: { organizationId }
  };
}

export function unlinkOrganizationError(error) {
  return {
    type: Constants.UNLINK_ORG_ERROR,
    error
  };
}

export function claimLinkedOrganizationAdminPending(payload) {
  return {
    type: Constants.CLAIM_LINKED_ORG_ADMIN_PENDING,
    payload
  };
}

export function claimLinkedOrganizationAdminSuccess(payload) {
  return {
    type: Constants.CLAIM_LINKED_ORG_ADMIN_SUCCESS,
    payload
  };
}

export function claimLinkedOrganizationAdminError(error) {
  return {
    type: Constants.CLAIM_LINKED_ORG_ADMIN_ERROR,
    error
  };
}

export function revalidateOrgLinkWithPinPending(payload) {
  return {
    type: Constants.REVALIDATE_ORG_LINK_PENDING,
    payload
  };
}

export function revalidateOrgLinkWithPinError(error) {
  return {
    type: Constants.REVALIDATE_ORG_LINK_ERROR,
    error
  };
}

export function revalidateOrgLinkWithPinSuccess(payload) {
  return {
    type: Constants.REVALIDATE_ORG_LINK_SUCCESS,
    payload
  };
}

export function onUpdateBreadCrumbs(payload) {
    return {
      type: Constants.UPDATE_BREADCRUMBS,
      payload
    };
  }

export function inviteApprovedPurchaserPending(payload) {
  return {
    type: Constants.INVITE_APPROVED_PURCHASER_PENDING,
    payload
  };
}

export function removeApprovedPurchaserPending(payload) {
  return {
    type: Constants.REMOVE_APPROVED_PURCHASER_PENDING,
    payload
  };
}

export function removeApprovedPurchaserSuccess(payload) {
  return {
    type: Constants.REMOVE_APPROVED_PURCHASER_SUCCESS,
    payload
  };
}

export function addUserToOrganizationGroupPending(payload) {
  return {
    type: Constants.ADD_USER_TO_ORG_GROUP_PENDING,
    payload
  };
}

export function addUserToOrganizationGroupSuccess(payload) {
  return {
    type: Constants.ADD_USER_TO_ORG_GROUP_SUCCESS,
    payload
  };
}

export function addUserToOrganizationGroupError(error) {
  return {
    type: Constants.ADD_USER_TO_ORG_GROUP_ERROR,
    error
  };
}

export function addUserToOrganizationRolePending(payload) {
  return {
    type: Constants.ADD_USER_TO_ORG_ROLE_PENDING,
    payload
  };
}

export function addUserToOrganizationRoleSuccess(payload) {
  return {
    type: Constants.ADD_USER_TO_ORG_ROLE_SUCCESS,
    payload
  };
}

export function addUserToOrganizationRoleError(error) {
  return {
    type: Constants.ADD_USER_TO_ORG_ROLE_ERROR,
    error
  };
}

export function removeUserFromOrganizationGroupConfirm(payload) {
  return {
    type: Constants.REMOVE_USER_FROM_ORG_GROUP_CONFIRM,
    payload
  };
}

export function removeUserFromOrganizationGroupPending(payload) {
  return {
    type: Constants.REMOVE_USER_FROM_ORG_GROUP_PENDING,
    payload
  };
}

export function removeUserFromOrganizationGroupSuccess(payload) {
  return {
    type: Constants.REMOVE_USER_FROM_ORG_GROUP_SUCCESS,
    payload
  };
}

export function removeUserFromOrganizationGroupError(error) {
  return {
    type: Constants.REMOVE_USER_FROM_ORG_GROUP_ERROR,
    error
  };
}

export function removeRoleFromUserConfirm(payload) {
    return {
      type: Constants.REMOVE_ROLE_CONFIRM,
      payload
    }
}

export function removeRoleFromUserPending(payload) {
    return {
      type: Constants.REMOVE_ROLE_PENDING,
      payload
    }
}

export function removeRoleFromUserSuccess(payload) {
    return {
      type: Constants.REMOVE_ROLE_SUCCESS,
      payload
    }
}

export function removeRoleFromUserError(error) {
    return {
      type: Constants.REMOVE_ROLE_ERROR,
      error
    }
}

export function deleteBillingAcctAssocPending(payload) {
  return {
    type: Constants.DELETE_BILLING_ACCT_ASSOC_PENDING,
    payload
  };
}

export function deleteBillingAcctAssocSuccess(payload) {
  return {
    type: Constants.DELETE_BILLING_ACCT_ASSOC_SUCCESS,
    payload
  };
}

export function deleteBillingAcctAssocError(payload) {
  return {
    type: Constants.DELETE_BILLING_ACCT_ASSOC_ERROR,
    payload
  };
}

export function updateBillingAcctAssocPending(payload) {
  return {
    type: Constants.UPDATE_BILLING_ACCT_ASSOC_PENDING,
    payload
  };
}

export function updateBillingAcctAssocSuccess(payload) {
  return {
    type: Constants.UPDATE_BILLING_ACCT_ASSOC_SUCCESS,
    payload
  };
}

export function updateBillingAcctAssocError(payload) {
  return {
    type: Constants.UPDATE_BILLING_ACCT_ASSOC_ERROR,
    payload
  };
}

export function deleteBillingAcctAssocConfirm(payload) {
  return {
    type: Constants.DELETE_BILLING_ACCT_ASSOC_CONFIRM,
    payload
  };
}

export function getGiftCardBalancePending(payload) {
  return {
    type: Constants.GET_GIFT_CARD_BALANCE_PENDING,
    payload
  };
}

export function getGiftCardBalanceSuccess(payload) {
  return {
    type: Constants.GET_GIFT_CARD_BALANCE_SUCCESS,
    payload
  };
}

export function getGiftCardBalanceError(payload) {
  return {
    type: Constants.GET_GIFT_CARD_BALANCE_ERROR,
    payload,
    error
  };
}

export function setCurrentGiftCard(payload) {
  return {
    type: Constants.SET_CURRENT_GIFT_CARD,
    payload
  };
}

export function tableOnInitColumns(payload) {
  return {
    type: Constants.TABLE_ON_INIT_COLUMNS,
    payload
  };
}

export function tableUpdateSettings(payload) {
  return {
    type: Constants.TABLE_UPDATE_SETTINGS,
    payload
  };
}

export function getOrderDetailsPending(payload) {
  return {
    type: Constants.GET_ORDER_DETAILS_PENDING,
    payload
  };
}

export function getOrderDetailsSuccess(payload) {
  return {
    type: Constants.GET_ORDER_DETAILS_SUCCESS,
    payload
  };
}

export function getOrderDetailsError(payload) {
  return {
    type: Constants.GET_ORDER_DETAILS_ERROR,
    payload
  };
}

export function getAccountStatementsPending(payload) {
  return {
    type: Constants.GET_ACCT_STMTS_PENDING,
    payload
  };
}

export function getAccountStatementsSuccess(payload) {
  return {
    type: Constants.GET_ACCT_STMTS_SUCCESS,

    payload
  };
}

export function getAccountStatementsError(payload) {
  return {
    type: Constants.GET_ACCT_STMTS_ERROR,
    payload,
    error
  };
}

export function getInvoicesShipmentsPending(payload) {
  return {
    type: Constants.GET_INVOICES_SHIPMENTS_PENDING,
    payload
  };
}

export function getInvoicesShipmentsSuccess(payload) {
  return {
    type: Constants.GET_INVOICES_SHIPMENTS_SUCCESS,
    payload
  };
}

export function getInvoicesShipmentsError(payload) {
  return {
    type: Constants.GET_INVOICES_SHIPMENTS_ERROR,
    payload,
    error
  };
}

export function fetchInvoicePdfPending(payload) {
  return {
    type: Constants.FETCH_INVOICE_PDF_PENDING,
    payload
  };
}

export function fetchInvoicePdfSuccess(payload) {
  return {
    type: Constants.FETCH_INVOICE_PDF_SUCCESS,
    payload
  };
}

export function fetchInvoicePdfError(payload) {
  return {
    type: Constants.FETCH_INVOICE_PDF_ERROR,
    payload,
    error
  };
}

export function getAccountTransactionsPending(payload) {
  return {
    type: Constants.GET_ACCT_TRANSACTIONS_PENDING,
    payload
  };
}

export function getAccountTransactionsSuccess(payload) {
  return {
    type: Constants.GET_ACCT_TRANSACTIONS_SUCCESS,
    payload
  };
}

export function getAccountTransactionsError(payload) {
  return {
    type: Constants.GET_ACCT_TRANSACTIONS_ERROR,
    payload,
    error
  };
}

export function setTransactionsCheckedState(payload) {
  return {
    type: Constants.SET_TRANSACTIONS_CHECKED_STATE,
    payload
  };
}

export function resetTransactionsCheckedState(payload) {
  return {
    type: Constants.RESET_TRANSACTIONS_CHECKED_STATE,
    payload
  };
}

export function createBraintreeClientInstancePending(payload) {
  return {
    type: Constants.CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING,
    payload
  };
}

export function createBraintreeClientInstanceSuccess(payload) {
  return {
    type: Constants.CREATE_BRAINTREE_CLIENT_INSTANCE_SUCCESS,
    payload
  };
}

export function createBraintreeClientInstanceError(payload) {
  return {
    type: Constants.CREATE_BRAINTREE_CLIENT_INSTANCE_ERROR,
    payload
  };
}

export function tokenizeBraintreeBankAccountPending(payload) {
  return {
    type: Constants.TOKENIZE_BRAINTREE_BANK_ACCOUNT_PENDING,
    payload
  };
}

export function tokenizeBraintreeBankAccountSuccess(payload) {
  return {
    type: Constants.TOKENIZE_BRAINTREE_BANK_ACCOUNT_SUCCESS,
    payload
  };
}

export function tokenizeBraintreeBankAccountError(payload) {
  return {
    type: Constants.TOKENIZE_BRAINTREE_BANK_ACCOUNT_ERROR,
    payload
  };
}

export function fetchAchPaymentMethodsPending(
  accountNumber,
  organizationId,
  bannerLocation
) {
  return {
    type: Constants.FETCH_ACH_PAYMENT_METHODS_PENDING,
    payload: {
      accountNumber,
      organizationId,
      bannerLocation
    }
  };
}

export function fetchAchPaymentMethodsSuccess(paymentMethods) {
  return {
    type: Constants.FETCH_ACH_PAYMENT_METHODS_SUCCESS,
    payload: paymentMethods
  };
}

export function fetchAchPaymentMethodsError(error) {
  return {
    type: Constants.FETCH_ACH_PAYMENT_METHODS_ERROR,
    error
  };
}

export function deleteAchPaymentMethodConfirm(
  organizationId,
  achPaymentMethodId,
  displayName,
  accountNumber
) {
  return {
    type: Constants.DELETE_ACH_PAYMENT_METHOD_CONFIRM,
    payload: {
      organizationId,
      achPaymentMethodId,
      displayName,
      accountNumber
    }
  };
}

export function deleteAchPaymentMethodPending(
  organizationId,
  achPaymentMethodId,
  accountNumber
) {
  return {
    type: Constants.DELETE_ACH_PAYMENT_METHOD_PENDING,
    payload: {
      organizationId,
      achPaymentMethodId,
      accountNumber
    }
  };
}

export function deleteAchPaymentMethodSuccess(achPaymentMethodId) {
  return {
    type: Constants.DELETE_ACH_PAYMENT_METHOD_SUCCESS,
    payload: achPaymentMethodId
  };
}

export function deleteAchPaymentMethodError(error) {
  return {
    type: Constants.DELETE_ACH_PAYMENT_METHOD_ERROR,
    error
  };
}

export function submitAchInvoicePaymentPending(payload) {
  return {
    type: Constants.SUBMIT_ACH_INVOICE_PAYMENT_PENDING,
    payload
  };
}

export function submitAchInvoicePaymentSuccess(payload) {
  return {
    type: Constants.SUBMIT_ACH_INVOICE_PAYMENT_SUCCESS,
    payload
  };
}

export function submitAchInvoicePaymentError(payload) {
  return {
    type: Constants.SUBMIT_ACH_INVOICE_PAYMENT_ERROR,
    payload
  };
}

export function resetAchPaymentInfo() {
  return {
    type: Constants.RESET_ACH_PAYMENT_INFO
  };
}

export function addACHPaymentMethodPending(payload) {
  return {
    type: Constants.ADD_ACH_PAYMENT_METHOD_PENDING,
    payload
  };
}

export function addACHPaymentMethodSuccess(payload) {
  return {
    type: Constants.ADD_ACH_PAYMENT_METHOD_SUCCESS,
    payload
  };
}

export function addACHPaymentMethodError(error) {
  return {
    type: Constants.ADD_ACH_PAYMENT_METHOD_ERROR,
    error
  };
}

export function setACHPaymentDefaultMethodPending(payload) {
  return {
    type: Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING,
    payload
  };
}

export function setACHPaymentDefaultMethodSuccess(payload) {
  return {
    type: Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_SUCCESS,
    payload
  };
}

export function setACHPaymentDefaultMethodError(error) {
  return {
    type: Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_ERROR,
    error
  };
}

export function getAccountBalancePending(payload) {
  return {
    type: Constants.GET_ACCT_BALANCE_PENDING,
    payload
  };
}

export function getAccountBalanceSuccess(payload) {
  return {
    type: Constants.GET_ACCT_BALANCE_SUCCESS,
    payload
  };
}

export function getAccountBalanceError(payload) {
  return {
    type: Constants.GET_ACCT_BALANCE_ERROR,
    payload,
    error
  };
}

export function downloadStatementPending(payload) {
  return {
    type: Constants.DOWNLOAD_STATEMENT_PENDING,
    payload
  };
}

export function downloadStatementSuccess(payload) {
  return {
    type: Constants.DOWNLOAD_STATEMENT_SUCCESS,
    payload
  };
}

export function downloadStatementError(payload) {
  return {
    type: Constants.DOWNLOAD_STATEMENT_ERROR,
    payload,
    error
  };
}

export function fetchStatementPdfPending(payload) {
  return {
    type: Constants.FETCH_STATEMENT_PDF_PENDING,
    payload
  };
}

export function fetchStatementPdfSuccess(payload) {
  return {
    type: Constants.FETCH_STATEMENT_PDF_SUCCESS,
    payload
  };
}

export function fetchStatementPdfError(payload) {
  return {
    type: Constants.FETCH_STATEMENT_PDF_ERROR,
    payload,
    error
  };
}

// Digital Media Actions ////////////////////////////
export function getDigitalMediaPending(payload) {
  return {
    type: Constants.GET_DIGITAL_MEDIA_PENDING,
    payload
  };
}

export function getDigitalMediaSuccess(payload) {
  return {
    type: Constants.GET_DIGITAL_MEDIA_SUCCESS,
    payload
  };
}

export function getDigitalMediaError(payload) {
  return {
    type: Constants.GET_DIGITAL_MEDIA_ERROR,
    error,
    payload
  };
}

export function setDigitalMediaStatus(payload) {
  return {
    type: Constants.SET_DIGITAL_MEDIA_STATUS,
    payload
  };
}

export function getLicensesPending() {
  return {
    type: Constants.GET_LICENSES_PENDING
  };
}

export function getLicensesSuccess(payload) {
  return {
    type: Constants.GET_LICENSES_SUCCESS,
    payload
  };
}

export function getLicensesError(payload) {
  return {
    type: Constants.GET_LICENSES_ERROR,
    payload
  };
}

export function getLicensePending(payload) {
  return {
    type: Constants.GET_LICENSE_PENDING,
    payload
  };
}

export function getLicenseSuccess(payload) {
  return {
    type: Constants.GET_LICENSE_SUCCESS,
    payload
  };
}

export function getLicenseError(payload) {
  return {
    type: Constants.GET_LICENSE_ERROR,
    payload
  };
}

export function confirmRevokeSeat(payload) {
  return {
    type: Constants.CONFIRM_REVOKE_SEAT,
    payload
  };
}

export function revokeSeatPending(payload) {
  return {
    type: Constants.REVOKE_SEAT_PENDING,
    payload
  };
}

export function revokeSeatSuccess(payload) {
  return {
    type: Constants.REVOKE_SEAT_SUCCESS,
    payload
  };
}

export function revokeSeatError(payload) {
  return {
    type: Constants.REVOKE_SEAT_ERROR,
    payload
  };
}

export function grantAccessAccountCheck(payload) {
  return {
    type: Constants.GRANT_ACCESS_ACCOUNT_CHECK,
    payload
  };
}

export function grantSeatPending(payload) {
  return {
    type: Constants.GRANT_SEAT_PENDING,
    payload
  };
}

export function grantSeatSuccess(payload) {
  return {
    type: Constants.GRANT_SEAT_SUCCESS,
    payload
  };
}

export function grantSeatError(payload) {
  return {
    type: Constants.GRANT_SEAT_ERROR,
    payload
  };
}

export function addManagerPending(payload) {
  return {
    type: Constants.ADD_MANAGER_PENDING,
    payload
  };
}

export function addManagerSuccess(payload) {
  return {
    type: Constants.ADD_MANAGER_SUCCESS,
    payload
  };
}

export function addManagerError(payload) {
  return {
    type: Constants.ADD_MANAGER_ERROR,
    payload
  };
}

export function removeManagerPending(payload) {
  return {
    type: Constants.REMOVE_MANAGER_PENDING,
    payload
  };
}

export function removeManagerSuccess(payload) {
  return {
    type: Constants.REMOVE_MANAGER_SUCCESS,
    payload
  };
}

export function removeManagerError(payload) {
  return {
    type: Constants.REMOVE_MANAGER_ERROR,
    payload
  };
}

export function clearRevokeError() {
  return {
    type: Constants.CLEAR_REVOKE_ERROR
  };
}

export function getProcessedOrdersPending(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDERS_PENDING,
    payload
  };
}

export function getProcessedOrdersSuccess(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDERS_SUCCESS,
    payload
  };
}

export function getProcessedOrdersError(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDERS_ERROR,
    payload
  };
}


export function getPendingOrderImagesPending(payload) {
  return {
    type: Constants.GET_PENDING_ORDER_IMAGES_PENDING,
    payload
  };
}

export function getPendingOrderImagesSuccess(payload) {
  return {
    type: Constants.GET_PENDING_ORDER_IMAGES_SUCCESS,
    payload
  };
}

export function getPendingOrderImagesError(payload) {
  return {
    type: Constants.GET_PENDING_ORDER_IMAGES_ERROR,
    payload
  };
}

export function getProcessedOrderImagesPending(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDER_IMAGES_PENDING,
    payload
  };
}

export function getProcessedOrderImagesSuccess(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDER_IMAGES_SUCCESS,
    payload
  };
}

export function getProcessedOrderImagesError(payload) {
  return {
    type: Constants.GET_PROCESSED_ORDER_IMAGES_ERROR,
    payload
  };
}

export function setIsOrderImagesLoading(){
  return {
    type: Constants.SET_IS_ORDER_IMAGE_LOADING
  };
}

export function getOrderImagesPending(payload) {
  return {
    type: Constants.GET_ORDER_IMAGES_PENDING,
    payload
  };
}

export function getOrderImagesSuccess(payload) {
  return {
    type: Constants.GET_ORDER_IMAGES_SUCCESS,
    payload
  };
}

export function getOrderImagesError(payload) {
  return {
    type: Constants.GET_ORDER_IMAGES_ERROR,
    payload
  };
}

export function getSearchedOrderImagesPending(payload) {
  return {
    type: Constants.GET_SEARCHED_ORDER_IMAGES_PENDING,
    payload
  };
}

export function getSearchedOrderImagesSuccess(payload) {
  return {
    type: Constants.GET_SEARCHED_ORDER_IMAGES_SUCCESS,
    payload
  };
}

export function getSearchedOrderImagesError(payload) {
  return {
    type: Constants.GET_SEARCHED_ORDER_IMAGES_ERROR,
    payload
  };
}

export function getOrdersPending(payload) {
  return {
    type: Constants.GET_ORDERS_PENDING,
    payload
  };
}

export function getOrdersSuccess(payload) {
  return {
    type: Constants.GET_ORDERS_SUCCESS,
    payload
  };
}

export function getOrdersError(payload) {
  return {
    type: Constants.GET_ORDERS_ERROR,
    payload
  };

}

export function getPendingOrdersPending(payload) {
  return {
    type: Constants.GET_PENDING_ORDERS_PENDING,
    payload
  };
}

export function getPendingOrdersSuccess(payload) {
  return {
    type: Constants.GET_PENDING_ORDERS_SUCCESS,
    payload
  };
}

export function getPendingOrdersError(payload) {
  return {
    type: Constants.GET_PENDING_ORDERS_ERROR,
    payload
  };
}

export function tableAppendRowData(payload) {
  return {
    type: Constants.TABLE_APPEND_ROW_DATA,
    payload
  };
}

export function getGiftCardPurchasesPending() {
  return {
    type: Constants.GET_GIFT_CARD_PURCHASES_PENDING
  };
}

export function getGiftCardPurchasesSuccess(payload) {
  return {
    type: Constants.GET_GIFT_CARD_PURCHASES_SUCCESS,
    payload
  };
}

export function getGiftCardPurchasesError(payload) {
  return {
    type: Constants.GET_GIFT_CARD_PURCHASES_ERROR,
    payload,
    error
  };
}

export function getResourcesPending(payload) {
  return {
    type: Constants.GET_RESOURCES_PENDING,
    payload
  };
}

export function getResourcesSuccess(payload) {
  return {
    type: Constants.GET_RESOURCES_SUCCESS,
    payload
  };
}

export function getResourcesError(payload) {
  return {
    type: Constants.GET_RESOURCES_ERROR,
    payload,
    error
  };
}

export function noResourceLocationsError(payload) {
  return {
    type: Constants.APP_ON_ERROR,
    data: {
      func: 'getResourceURL',
      location: 'digitalMediaLogic.js',
      err: payload
    }
  };
}

export function getDownloadsPending(payload, { onError, onSuccess, onMultiDownload } = {}) {
  return {
    type: Constants.GET_DOWNLOADS_PENDING,
    payload,
    meta: {
      onError,
      onSuccess,
      onMultiDownload
    }
  };
}

export function getDownloadsSuccess(payload) {
  return {
    type: Constants.GET_DOWNLOADS_SUCCESS,
    payload
  };
}

export function getDownloadsError(payload) {
  return {
    type: Constants.GET_DOWNLOADS_ERROR,
    payload,
    error
  };
}

export function getMediaContentChildrenPending(payload) {
  return {
    type: Constants.GET_MEDIA_CONTENT_CHILDREN_PENDING,
    payload
  };
}

export function getMediaContentChildrenSuccess(payload) {
  return {
    type: Constants.GET_MEDIA_CONTENT_CHILDREN_SUCCESS,
    payload
  };
}

export function getMediaContentChildrenError(payload) {
  return {
    type: Constants.GET_MEDIA_CONTENT_CHILDREN_ERROR,
    payload,
    error
  };
}

export function clearMultiDownloads() {
  return {
    type: Constants.CLEAR_MULTI_DOWNLOADS
  };
}

export function getOrderSearchPending(payload) {
  return {
    type: Constants.GET_ORDER_SEARCH_PENDING,
    payload
  };
}

export function getOrderSearchSuccess(payload) {
  return {
    type: Constants.GET_ORDER_SEARCH_SUCCESS,

    payload
  };
}

export function getOrderSearchError(payload) {
  return {
    type: Constants.GET_ORDER_SEARCH_ERROR,
    error,
    payload
  };
}

export function addToCartPending(items, { onSuccess, onError } = {}) {
  return {
    type: Constants.ADD_TO_CART_PENDING,
    payload: {
      items,
      meta: {
        onSuccess,
        onError
      }
    }
  };
}

export function addToCartSuccess(payload) {
  return {
    type: Constants.ADD_TO_CART_SUCCESS,
    payload
  };
}

export function addToCartError(payload) {
  return {
    type: Constants.ADD_TO_CART_ERROR,
    error,
    payload
  };
}

export function getCodesPending(payload) {
  return {
    type: Constants.GET_CODES_PENDING,
    payload
  };
}

export function getCodeSuccess(payload) {
  return {
    type: Constants.GET_CODE_SUCCESS,
    payload
  };
}

export function getCodeError(payload) {
  return {
    type: Constants.GET_CODE_ERROR,
    error,
    payload
  };
}

export function setSearchResults(payload) {
  return {
    type: Constants.SET_SEARCH_RESULTS,
    payload
  };
}

export function setRedirectURL(payload) {
  return {
    type: Constants.SET_REDIRECT_URL,
    payload
  };
}

export function searchDigitalMediaPending(payload) {
  return {
    type: Constants.SEARCH_MEDIA_PENDING,
    payload
  };
}

export function searchDigitalMediaSuccess(payload) {
  return {
    type: Constants.SEARCH_MEDIA_SUCCESS,
    payload
  };
}

export function searchRedeemedMediaPending(
  payload,
  { onError, onSuccess } = {}
) {
  return {
    type: Constants.SEARCH_REDEEMED_MEDIA_PENDING,
    payload,
    meta: {
      onError,
      onSuccess
    }
  };
}

// Pass me the paymentId from the query params from the PCI form, yo
export function getPaymentInfoPending(payload) {
  return {
    type: Constants.GET_PAYMENT_INFO_PENDING,
    payload
  };
}

export function getPaymentInfoSuccess(payload) {
  return {
    type: Constants.GET_PAYMENT_INFO_SUCCESS,
    payload
  };
}

export function updateGiftCardBalance(payload) {
  return {
    type: Constants.UPDATE_GIFT_CARD_BALANCE,
    payload
  };
}

export function emailGiftCardPending(payload) {
  return {
    type: Constants.EMAIL_GIFT_CARD_PENDING,
    payload
  };
}

export function emailGiftCardSuccess(payload) {
  return {
    type: Constants.EMAIL_GIFT_CARD_SUCCESS,
    payload
  };
}

export function emailGiftCardError(payload) {
  return {
    type: Constants.EMAIL_GIFT_CARD_ERROR,
    error,
    payload
  };
}

export function pinManagementRequest(payload) {
  return {
    type: Constants.PIN_MANAGEMENT_REQUEST,
    payload
  };
}

export function forgotPinRequestPending(payload) {
  return {
    type: Constants.FORGOT_PIN_REQUEST_PENDING,
    payload
  };
}

export function newPinRequestPending(payload) {
  return {
    type: Constants.NEW_PIN_REQUEST_PENDING,
    payload
  };
}

export function updateFormState(payload) {
  return {
    type: Constants.UPDATE_FORM_STATE,
    payload
  };
}

export function clearFormState(payload) {
  return {
    type: Constants.CLEAR_FORM_STATE,
    payload
  };
}

export function getSubscriptionsPending() {
  return {
    type: Constants.GET_SUBSCRIPTIONS_PENDING
  };
}

export function getSubscriptionsSuccess(payload) {
  return {
    type: Constants.GET_SUBSCRIPTIONS_SUCCESS,
    payload
  };
}

export function getSubscriptionsError(payload) {
  return {
    type: Constants.GET_SUBSCRIPTIONS_ERROR,
    error,
    payload
  };
}

export function getOrgSubscriptionsPending(organizationId) {
  return {
    type: Constants.GET_ORG_SUBSCRIPTIONS_PENDING,
    payload: {
        organizationId
      }
  };
}

export function getOrgSubscriptionsSuccess(payload) {
  return {
    type: Constants.GET_ORG_SUBSCRIPTIONS_SUCCESS,
    payload
  };
}

export function getOrgSubscriptionsError(payload) {
  return {
    type: Constants.GET_ORG_SUBSCRIPTIONS_ERROR,
    error,
    payload
  };
}

export function getSubscriptionDetailsPending(subscriptionId) {
    return {
      type: Constants.GET_SUBSCRIPTION_DETAILS_PENDING,
      payload: {
        subscriptionId
      }
    };
  }

  export function getSubscriptionDetailsSuccess(subscriptionId, subscription) {
    return {
      type: Constants.GET_SUBSCRIPTION_DETAILS_SUCCESS,
      payload: {
        subscriptionId,
        subscription
      }
    };
  }

  export function getSubscriptionDetailsError(subscriptionId, error, status) {
    return {
      type: Constants.GET_SUBSCRIPTION_DETAILS_ERROR,
      payload: {
        subscriptionId,
        status,
        error
      },
      error: true
    };
  }

  export function updateOrgSubscriptionContactPending(
    { subscriptionId, subscriptionName, contact, paymentMethodId, paymentMethodType, organizationId }, { onSuccess, onError } = {}
    ) {
    return {
      type: Constants.UPDATE_ORG_SUBSCRIPTION_CONTACT_PENDING,
      payload: {
        subscriptionId,
        contact,
        paymentMethodId,
        paymentMethodType,
        organizationId,
        subscriptionName
      },
      meta: {
        onSuccess,
        onError
      }
    };
  }

  export function updateOrgSubscriptionContactSuccess(payload) {
    return {
      type: Constants.UPDATE_ORG_SUBSCRIPTION_CONTACT_SUCCESS,
      payload
    };
  }

  export function updateOrgSubscriptionContactError(payload) {
    return {
      type: Constants.UPDATE_ORG_SUBSCRIPTION_CONTACT_ERROR,
      error,
      payload
    };
  }

export function cancelSubscriptionPending(payload) {
  return {
    type: Constants.CANCEL_SUBSCRIPTION_PENDING,
    payload
  };
}

export function cancelSubscriptionSuccess(payload) {
  return {
    type: Constants.CANCEL_SUBSCRIPTION_SUCCESS,
    payload
  };
}

export function cancelSubscriptionError(payload) {
  return {
    type: Constants.CANCEL_SUBSCRIPTION_ERROR,
    error,
    payload
  };
}

export function setSubscriptionsLoaded(payload) {
  return {
    type: Constants.SET_SUBSCRIPTIONS_LOADING,
    payload
  };
}

export function setOrgSubscriptionsLoaded(payload) {
  return {
    type: Constants.SET_ORG_SUBSCRIPTIONS_LOADING,
    payload
  };
}

export function updateSubscriptionPaymentPending(payload, { onSuccess, onError } = {}) {
  return {
    type: Constants.UPDATE_SUBSCRIPTIONS_PAYMENT_PENDING,
    payload,
    meta: {
        onSuccess,
        onError
    }
  };
}

export function updateSubscriptionPaymentSuccess(payload) {
  return {
    type: Constants.UPDATE_SUBSCRIPTIONS_PAYMENT_SUCCESS,
    payload
  };
}

export function updateSubscriptionPaymentError(payload) {
  return {
    type: Constants.UPDATE_SUBSCRIPTIONS_PAYMENT_ERROR,
    payload,
    error,
  };
}

export function reactivateSubscriptionPending(
  payload,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.UPDATE_SUBSCRIPTION_STATUS_PENDING,
    payload,
    meta: {
      onSuccess,
      onError
    }
  };
}

export function reactivateSubscriptionSuccess() {
  return {
    type: Constants.UPDATE_SUBSCRIPTION_STATUS_SUCCESS
  };
}

export function reactivateSubscriptionError(payload) {
  return {
    type: Constants.UPDATE_SUBSCRIPTION_STATUS_ERROR,
    error,
    payload
  };
}

export function getPersonalAutoshipsPending() {
  return {
    type: Constants.GET_PERSONAL_AUTOSHIPS_PENDING
  };
}

export function getPersonalAutoshipsSuccess(autoships, errors) {
  return {
    type: Constants.GET_PERSONAL_AUTOSHIPS_SUCCESS,
    payload: {
      autoships,
      errors
    }
  };
}

export function getPersonalAutoshipsError(payload) {
  return {
    type: Constants.GET_PERSONAL_AUTOSHIPS_ERROR,
    error: true,
    payload
  };
}

export function getOrgAutoshipsPending(organizationId) {
  return {
    type: Constants.GET_ORG_AUTOSHIPS_PENDING,
    payload: {
      organizationId
    }
  };
}

export function getOrgAutoshipsSuccess(organizationId, autoships, errors) {
  return {
    type: Constants.GET_ORG_AUTOSHIPS_SUCCESS,
    payload: {
      organizationId,
      autoships,
      errors
    }
  };
}

export function getOrgAutoshipsError(organizationId, error) {
  return {
    type: Constants.GET_ORG_AUTOSHIPS_ERROR,
    error: true,
    payload: {
      organizationId,
      error
    }
  };
}

export function getAutoshipDetailsPending(autoshipId) {
  return {
    type: Constants.GET_AUTOSHIP_DETAILS_PENDING,
    payload: {
      autoshipId
    }
  };
}

export function getAutoshipDetailsSuccess(autoshipId, autoship) {
  return {
    type: Constants.GET_AUTOSHIP_DETAILS_SUCCESS,
    payload: {
      autoshipId,
      autoship
    }
  };
}

export function getAutoshipDetailsError(autoshipId, error, status) {
  return {
    type: Constants.GET_AUTOSHIP_DETAILS_ERROR,
    payload: {
      autoshipId,
      status,
      error
    },
    error: true
  };
}

export function updateAutoshipsWithShippingAddress({ address, existingId }) {
  return {
    type: Constants.UPDATE_AUTOSHIPS_WITH_SHIPPING_ADDRESS,
    payload: { address, existingId }
  };
}

export function getUserPending(lifewayId, organizationId) {
  return {
    type: Constants.GET_USER_PENDING,
    payload: {
      lifewayId,
      organizationId
    }
  };
}

export function getUserSuccess(lifewayId, name) {
  return {
    type: Constants.GET_USER_SUCCESS,
    payload: {
      lifewayId,
      name
    }
  };
}

export function getUserError(lifewayId, error) {
  return {
    type: Constants.GET_USER_ERROR,
    error: true,
    payload: {
      lifewayId,
      error
    }
  };
}

export function createAutoshipPending(
  {
    organizationId,
    name,
    items,
    orderDate,
    frequency,
    paymentMethodId,
    shippingAddressId,
    type
  },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.CREATE_AUTOSHIP_PENDING,
    payload: {
      organizationId,
      name,
      items,
      orderDate,
      frequency,
      paymentMethodId,
      shippingAddressId,
      type
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function createAutoshipSuccess(autoshipId, { organizationId, name }) {
  return {
    type: Constants.CREATE_AUTOSHIP_SUCCESS,
    payload: {
      autoshipId,
      organizationId,
      name
    }
  };
}

export function createAutoshipError({ organizationId, name }, error) {
  return {
    type: Constants.CREATE_AUTOSHIP_ERROR,
    payload: {
      organizationId,
      name,
      error
    },
    error: true
  };
}

export function updateAutoshipShippingAddressPending(
  address,
  autoshipId,
  organizationId,
  billingAccountId,
  close,
  setSubmitting
) {
  return {
    type: Constants.UPDATE_AUTOSHIP_SHIPPING_ADDRESS_PENDING,
    payload: {
      address,
      autoshipId,
      organizationId,
      billingAccountId,
      close,
      setSubmitting
    }
  };
}

export function updateAutoshipShippingAddressSuccess(payload) {
  return {
    type: Constants.UPDATE_AUTOSHIP_SHIPPING_ADDRESS_SUCCESS,
    payload
  };
}

export function updateAutoshipShippingAddressError(error) {
  return {
    type: Constants.UPDATE_AUTOSHIP_SHIPPING_ADDRESS_ERROR,
    error
  };
}

export function transferAutoshipBetweenAccountAddresses(payload) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_BETWEEN_ACCOUNT_ADDRESSES,
    payload
  };
}

export function transferAutoshipBetweenUserAddresses(payload) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_BETWEEN_ACCOUNT_ADDRESSES,
    payload
  };
}

export function updateAutoshipPaymentMethodPending(
  { paymentMethodId, paymentMethodType, autoshipId, organizationId },
  close
) {
  return {
    type: Constants.UPDATE_AUTOSHIP_PAYMENT_METHOD_PENDING,
    payload: {
      paymentMethodId,
      paymentMethodType,
      autoshipId,
      organizationId,
      close
    }
  };
}

export function updateAutoshipPaymentMethodSuccess(payload) {
  return {
    type: Constants.UPDATE_AUTOSHIP_PAYMENT_METHOD_SUCCESS,
    payload
  };
}

export function updateAutoshipPaymentMethodError(error) {
  return {
    type: Constants.UPDATE_AUTOSHIP_PAYMENT_METHOD_ERROR,
    error
  };
}

export function updateAutoshipContactPending(
  { autoshipId, contact, paymentMethodId, paymentMethodType, organizationId },
  close
) {
  return {
    type: Constants.UPDATE_AUTOSHIP_CONTACT_PENDING,
    payload: {
      autoshipId,
      contact,
      paymentMethodId,
      paymentMethodType,
      organizationId,
      close
    }
  };
}

export function updateAutoshipContactSuccess(payload) {
  return {
    type: Constants.UPDATE_AUTOSHIP_CONTACT_SUCCESS,
    payload
  };
}

export function updateAutoshipContactError(error) {
  return {
    type: Constants.UPDATE_AUTOSHIP_CONTACT_ERROR,
    error
  };
}

export function submitAutoshipOrderPending(
  organizationId,
  autoshipId,
  preserveNextOrderDate,
  { onError, onSuccess }
) {
  return {
    type: Constants.SUBMIT_AUTOSHIP_ORDER_PENDING,
    payload: {
      organizationId,
      autoshipId,
      preserveNextOrderDate
    },
    meta: {
      onError,
      onSuccess
    }
  };
}

export function submitAutoshipOrderSuccess(payload) {
  return {
    type: Constants.SUBMIT_AUTOSHIP_ORDER_SUCCESS,
    payload
  };
}

export function submitAutoshipOrderError(error) {
  return {
    type: Constants.SUBMIT_AUTOSHIP_ORDER_ERROR,
    error
  };
}

export function getProductAvailabilityPending(itemNumbers) {
  return {
    type: Constants.GET_PRODUCT_AVAILABILITY_PENDING,
    payload: {
      itemNumbers
    }
  };
}

export function getProductAvailabilitySuccess(availability) {
  return {
    type: Constants.GET_PRODUCT_AVAILABILITY_SUCCESS,
    payload: {
      availability
    }
  };
}

export function getProductAvailabilityError(itemNumbers, error) {
  return {
    type: Constants.GET_PRODUCT_AVAILABILITY_ERROR,
    error: true,
    payload: {
      itemNumbers,
      error
    }
  };
}

export function getProductAllDetailsPending(itemNumbers) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_PENDING,
    payload: {
      itemNumbers
    }
  };
}

export function getProductAllDetailsSuccess(products) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_SUCCESS,
    payload: {
      products
    }
  };
}

export function getProductAllDetailsError(itemNumbers, error) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_ERROR,
    error: true,
    payload: {
      itemNumbers,
      error
    }
  };
}

export function getProductAllDetailsAndFindProductsReplacementsPending(items) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_PENDING,
    payload: {
      items
    }
  };
}

export function getProductAllDetailsAndFindProductsReplacementsSuccess(
  products
) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_SUCCESS,
    payload: {
      products
    }
  };
}

export function getProductAllDetailsAndFindProductsReplacementsError(
  items,
  error
) {
  return {
    type: Constants.GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_ERROR,
    error: true,
    payload: {
      items,
      error
    }
  };
}

export function cancelAutoshipPending(
  autoship,
  cancelReason,
  { onSuccess, onError }
) {
  return {
    type: Constants.CANCEL_AUTOSHIP_PENDING,
    payload: {
      autoship,
      cancelReason
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function cancelAutoshipSuccess(autoshipId) {
  return {
    type: Constants.CANCEL_AUTOSHIP_SUCCESS,
    payload: {
      autoshipId
    }
  };
}

export function cancelAutoshipError(autoshipId, error) {
  return {
    type: Constants.CANCEL_AUTOSHIP_SUCCESS,
    payload: {
      autoshipId,
      error
    },
    error: true
  };
}

export function removeAutoshipItemPending(
  autoshipId,
  { productSku, organizationId },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.REMOVE_AUTOSHIP_ITEM_PENDING,
    payload: {
      autoshipId,
      productSku,
      organizationId
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function removeAutoshipItemSuccess(
  autoshipId,
  { productSku, organizationId }
) {
  return {
    type: Constants.REMOVE_AUTOSHIP_ITEM_SUCCESS,
    payload: {
      autoshipId,
      organizationId,
      productSku
    }
  };
}

export function removeAutoshipItemError(
  autoshipId,
  { productSku, organizationId },
  error
) {
  return {
    type: Constants.REMOVE_AUTOSHIP_ITEM_ERROR,
    payload: {
      autoshipId,
      productSku,
      organizationId,
      error
    },
    error: true
  };
}

export function changeAutoshipItemQuantityPending(
  autoshipId,
  { productSku, newQuantity, organizationId },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_ITEM_QUANTITY_PENDING,
    payload: {
      autoshipId,
      organizationId,
      productSku,
      newQuantity
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function changeAutoshipItemQuantitySuccess(
  autoshipId,
  { productSku, newQuantity, organizationId }
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_ITEM_QUANTITY_SUCCESS,
    payload: {
      autoshipId,
      organizationId,
      productSku,
      newQuantity
    }
  };
}

export function changeAutoshipItemQuantityError(
  autoshipId,
  { productSku, newQuantity, organizationId },
  error
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_ITEM_QUANTITY_ERROR,
    payload: {
      autoshipId,
      organizationId,
      productSku,
      newQuantity,
      error
    },
    error: true
  };
}

export function transferAutoshipItemToExistingPending(
  { originId, destinationId, productSku, organizationId },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_PENDING,
    payload: {
      originId,
      destinationId,
      productSku,
      organizationId
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function transferAutoshipItemToExistingSuccess({
  originId,
  destinationId,
  organizationId,
  productSku
}) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_SUCCESS,
    payload: {
      originId,
      destinationId,
      organizationId,
      productSku
    }
  };
}

export function transferAutoshipItemToExistingError(
  { originId, destinationId, organizationId, productSku },
  error
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_ERROR,
    payload: {
      originId,
      destinationId,
      organizationId,
      productSku,
      error
    },
    error: true
  };
}

export function transferAutoshipItemToExistingWithQuantityPending(
  { originId, destinationId, productSku, newQuantity, organizationId },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_PENDING,
    payload: {
      originId,
      destinationId,
      productSku,
      newQuantity,
      organizationId
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function transferAutoshipItemToExistingWithQuantitySuccess({
  originId,
  destinationId,
  organizationId,
  productSku,
  newQuantity
}) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_SUCCESS,
    payload: {
      originId,
      destinationId,
      organizationId,
      productSku,
      newQuantity
    }
  };
}

export function transferAutoshipItemToExistingWithQuantityError(
  { originId, destinationId, organizationId, productSku, newQuantity },
  errorSource,
  error
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_ERROR,
    payload: {
      originId,
      destinationId,
      organizationId,
      productSku,
      newQuantity,
      errorSource,
      error
    },
    error: true
  };
}

export function transferAutoshipItemToNewPending(
  { sourceId, newAutoshipName, productSku, newQuantity, organizationId },
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_NEW_PENDING,
    payload: {
      sourceId,
      newAutoshipName,
      productSku,
      newQuantity,
      organizationId
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function transferAutoshipItemToNewSuccess({
  sourceId,
  newAutoshipId,
  newAutoshipName,
  organizationId,
  productSku,
  newQuantity
}) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_NEW_SUCCESS,
    payload: {
      sourceId,
      newAutoshipId,
      newAutoshipName,
      organizationId,
      productSku,
      newQuantity
    }
  };
}

export function transferAutoshipItemToNewError(
  {
    sourceId,
    newAutoshipId,
    newAutoshipName,
    organizationId,
    productSku,
    newQuantity
  },
  errorSource,
  error
) {
  return {
    type: Constants.TRANSFER_AUTOSHIP_ITEM_TO_NEW_ERROR,
    payload: {
      sourceId,
      newAutoshipId,
      newAutoshipName,
      organizationId,
      productSku,
      newQuantity,
      errorSource,
      error
    },
    error: true
  };
}

export function addAutoshipItemsPending(
  autoshipId,
  { organizationId, items },
  { onSuccess, onError, refetchAutoships = true } = {}
) {
  return {
    type: Constants.ADD_AUTOSHIP_ITEMS_PENDING,
    payload: {
      autoshipId,
      organizationId,
      items
    },
    meta: {
      onSuccess,
      onError,
      refetchAutoships
    }
  };
}

export function addAutoshipItemsSuccess(autoshipId, { organizationId, items }) {
  return {
    type: Constants.ADD_AUTOSHIP_ITEMS_SUCCESS,
    payload: {
      autoshipId,
      organizationId,
      items
    }
  };
}

export function addAutoshipItemsError(
  autoshipId,
  { organizationId, items },
  error
) {
  return {
    type: Constants.ADD_AUTOSHIP_ITEMS_ERROR,
    payload: {
      autoshipId,
      organizationId,
      items,
      error
    },
    error: true
  };
}

export function changeAutoshipFrequencyPending(
  organizationId,
  autoshipId,
  frequency,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_FREQUENCY_PENDING,
    payload: {
      organizationId,
      autoshipId,
      frequency
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function changeAutoshipFrequencySuccess(autoshipId, frequency) {
  return {
    type: Constants.CHANGE_AUTOSHIP_FREQUENCY_SUCCESS,
    payload: {
      autoshipId,
      frequency
    }
  };
}

export function changeAutoshipFrequencyError(autoshipId, error) {
  return {
    type: Constants.CHANGE_AUTOSHIP_FREQUENCY_ERROR,
    payload: {
      autoshipId,
      error
    },
    error: true
  };
}

export function skipAutoshipNextOrderPending(
  autoshipId,
  organizationId,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.SKIP_AUTOSHIP_NEXT_ORDER_PENDING,
    payload: {
      autoshipId,
      organizationId
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function skipAutoshipNextOrderSuccess(autoshipId, organizationId) {
  return {
    type: Constants.SKIP_AUTOSHIP_NEXT_ORDER_SUCCESS,
    payload: {
      autoshipId,
      organizationId
    }
  };
}

export function skipAutoshipNextOrderError(autoshipId, organizationId, error) {
  return {
    type: Constants.SKIP_AUTOSHIP_NEXT_ORDER_SUCCESS,
    payload: {
      autoshipId,
      organizationId,
      error
    },
    error: true
  };
}

export function changeAutoshipNextOrderDatePending(
  organizationId,
  autoshipId,
  nextShipDate,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NEXT_ORDER_DATE_PENDING,
    payload: {
      organizationId,
      autoshipId,
      nextShipDate
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function changeAutoshipNextOrderDateSuccess(autoshipId, nextShipDate) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NEXT_ORDER_DATE_SUCCESS,
    payload: {
      autoshipId,
      nextShipDate
    }
  };
}

export function changeAutoshipNextOrderDateError(autoshipId, error) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NEXT_ORDER_DATE_ERROR,
    payload: {
      autoshipId,
      error
    },
    error: true
  };
}

export function changeAutoshipNamePending(
  organizationId,
  autoshipId,
  newName,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NAME_PENDING,
    payload: {
      organizationId,
      autoshipId,
      newName
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function changeAutoshipNameSuccess(autoshipId, newName) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NAME_SUCCESS,
    payload: {
      autoshipId,
      newName
    }
  };
}

export function changeAutoshipNameError(autoshipId, error) {
  return {
    type: Constants.CHANGE_AUTOSHIP_NAME_ERROR,
    payload: {
      autoshipId,
      error
    },
    error: true
  };
}

export function reactivateAutoshipPending(
  autoshipId,
  organizationId,
  { onSuccess, onError } = {}
) {
  return {
    type: Constants.REACTIVATE_AUTOSHIP_PENDING,
    payload: {
      autoshipId,
      organizationId
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function reactivateAutoshipSuccess(autoshipId, organizationId) {
  return {
    type: Constants.REACTIVATE_AUTOSHIP_SUCCESS,
    payload: {
      autoshipId,
      organizationId
    }
  };
}

export function reactivateAutoshipError(autoshipId, organizationId, error) {
  return {
    type: Constants.REACTIVATE_AUTOSHIP_ERROR,
    payload: {
      autoshipId,
      organizationId,
      error
    },
    error: true
  };
}

export function fetchInvites(inviteeId) {
  return {
    type: Constants.FETCH_INVITATIONS,
    payload: {
      inviteeId
    }
  };
}

export function fetchInvitesSuccess(invitations) {
  return {
    type: Constants.FETCH_INVITATIONS_SUCCESS,
    payload: {
      invitations
    }
  };
}

export function fetchInvitesError(error) {
  return {
    type: Constants.FETCH_INVITATIONS_ERROR,
    error
  };
}
export function fetchInvite(inviteId) {
  return {
    type: Constants.FETCH_INVITATION,
    payload: {
      inviteId
    }
  };
}

export function fetchInviteSuccess(invitation) {
  return {
    type: Constants.FETCH_INVITATION_SUCCESS,
    payload: {
      ...invitation
    }
  };
}

export function fetchInviteError(error) {
  return {
    type: Constants.FETCH_INVITATION_ERROR,
    error
  };
}

export function fetchPendingInvitesByLicense(licenseId) {
  return {
    type: Constants.FETCH_LICENSE_INVITATIONS,
    payload: {
      licenseId
    }
  };
}

export function fetchPendingInvitesByLicenseSuccess(invitations) {
  return {
    type: Constants.FETCH_LICENSE_INVITATIONS_SUCCESS,
    payload: {
      invitations
    }
  };
}

export function fetchPendingInvitesByLicenseError(error) {
  return {
    type: Constants.FETCH_LICENSE_INVITATIONS_ERROR,
    error
  };
}

export function fetchPendingInvitesByOrganization(organizationId) {
  return {
    type: Constants.FETCH_ORG_INVITATIONS,
    payload: {
      organizationId
    }
  };
}

export function fetchPendingInvitesByOrganizationSuccess(invitations) {
  return {
    type: Constants.FETCH_ORG_INVITATIONS_SUCCESS,
    payload: {
      invitations
    }
  };
}

export function fetchPendingInvitesByOrganizationError(error) {
  return {
    type: Constants.FETCH_ORG_INVITATIONS_ERROR,
    error
  };
}

export function sendLicenseInvitePending(payload) {
  return {
    type: Constants.SEND_LICENSE_INVITE_PENDING,
    payload
  };
}

export function sendLicenseInviteSuccess(payload) {
  return {
    type: Constants.SEND_LICENSE_INVITE_SUCCESS,
    payload
  };
}

export function sendLicenseInviteError(payload) {
  return {
    type: Constants.SEND_LICENSE_INVITE_ERROR,
    payload
  };
}

export function sendGroupInvite(payload) {
  return {
    type: Constants.SEND_GROUP_INVITE,
    payload
  };
}

export function sendGroupInviteSuccess(invitation) {
  return {
    type: Constants.SEND_GROUP_INVITE_SUCCESS,
    payload: {
      ...invitation
    }
  };
}

export function sendGroupInviteError(error) {
  return {
    type: Constants.SEND_GROUP_INVITE_ERROR,
    error
  };
}

export function resendInvite(payload) {
  return {
    type: Constants.RESEND_INVITE,
    payload
  };
}

export function resendInviteSuccess(invitation) {
  return {
    type: Constants.RESEND_INVITE_SUCCESS,
    payload: {
      ...invitation
    }
  };
}

export function resendInviteError(error) {
  return {
    type: Constants.RESEND_INVITE_ERROR,
    error
  };
}

export function respondToInvite(
  { inviteId, response, email },
  successCallback
) {
  return {
    type: Constants.RESPOND_TO_INVITE,
    payload: {
      inviteId,
      response,
      email
    },
    successCallback
  };
}

export function respondToInviteSuccess(inviteId) {
  return {
    type: Constants.RESPOND_TO_INVITE_SUCCESS,
    payload: {
      inviteId
    }
  };
}

export function respondToInviteError(inviteId, error) {
  return {
    type: Constants.RESPOND_TO_INVITE_ERROR,
    payload: {
      inviteId
    },
    error
  };
}

export function cancelInviteConfirmation(payload) {
  return {
    type: Constants.CANCEL_INVITE_CONFIRMATION,
    payload
  };
}

export function cancelInvitePending(payload) {
  return {
    type: Constants.CANCEL_INVITE_PENDING,
    payload
  };
}

export function cancelInviteSuccess(invitation) {
  return {
    type: Constants.CANCEL_INVITE_SUCCESS,
    payload: {
      ...invitation
    }
  };
}

export function cancelInviteError(inviteId, error) {
  return {
    type: Constants.CANCEL_INVITE_ERROR,
    payload: {
      inviteId
    },
    error
  };
}

export function addPendingTaxExemption(organizationId) {
  return {
    type: Constants.ADD_PENDING_TAX_EXEMPTION,
    payload: {
      organizationId
    }
  };
}

export function submitNewsletterFormPending(payload) {
  return {
    type: Constants.SUBMIT_NEWSLETTER_FORM_PENDING,
    payload
  };
}

export function submitNewsletterFormSuccess(payload) {
  return {
    type: Constants.SUBMIT_NEWSLETTER_FORM_SUCCESS,
    payload
  };
}

export function submitNewsletterFormError(payload) {
  return {
    type: Constants.SUBMIT_NEWSLETTER_FORM_ERROR,
    payload,
    error
  };
}

export function transferSubscriptionPending({
  contactPerson, subscriptionId, organizationId, individualId, accountId, paymentMethod, billingAddress, paymentMethodType
}, { onSuccess, onError } = {}) {
  return {
    type: Constants.TRANSFER_SUBSCRIPTION_PENDING,
    payload : {
      subscriptionId,
      organizationId,
      individualId,
      accountId,
      paymentMethod,
      billingAddress,
      paymentMethodType,
      contactPerson
    },
    meta: {
      onSuccess,
      onError
    }
  };
}

export function transferSubscriptionSuccess(payload) {
  return {
    type: Constants.TRANSFER_SUBSCRIPTION_SUCCESS,
    payload
  };
}

export function transferSubscriptionError(payload) {
  return {
    type: Constants.TRANSFER_SUBSCRIPTION_ERROR,
    payload,
    error
  };
}

export function getContentfulLinksPending() {
  return {
    type: Constants.GET_CONTENTFUL_LINKS_PENDING,
  };
}

export function getContentfulLinksSuccess(payload) {
  return {
    type: Constants.GET_CONTENTFUL_LINKS_SUCCESS,
    payload
  };
}

export function getContentfulLinksError(payload) {
  return {
    type: Constants.GET_CONTENTFUL_LINKS_ERROR,
    error,
    payload
  };
}
