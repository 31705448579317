import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed, reset } from 'redux-form';
import {
  GET_SHIPPING_ADDRESSES_PENDING,
  ADD_USER_SHIPPING_ADDRESS_PENDING,
  DELETE_USER_SHIPPING_ADDRESS_CONFIRM,
  DELETE_USER_SHIPPING_ADDRESS_PENDING,
  UPDATE_USER_SHIPPING_ADDRESS_PENDING,
  UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM,
  DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING,
} from '../constants';
import {
  getShippingAddressesSuccess,
  getShippingAddressesError,
  addUserShippingAddressSuccess,
  addUserShippingAddressError,
  toggleForm,
  toggleModal,
  triggerModal,
  deleteUserShippingAddressSuccess,
  deleteUserShippingAddressPending,
  updateUserShippingAddressSuccess,
  updateUserShippingAddressError,
  setBannerState,
  updateAccountShippingAddressSuccess,
  updateAccountShippingAddressError,
  addAddressBeingProcessed,
  removeAddressBeingProcessed,
  deleteAccountShippingAddressSuccess,
  deleteAccountShippingAddressPending,
  addAccountShippingAddressError,
  addAccountShippingAddressSuccess,
  updateAutoshipsWithShippingAddress
} from '../actions/AppActions';


export const getShippingAddressesLogic = createLogic({
  type: GET_SHIPPING_ADDRESSES_PENDING,
  latest: true,
  process({ httpClient, getState }, dispatch, done) {
    httpClient(dispatch)
      .get('/shipping-addresses')
      .then(res => {
        dispatch(getShippingAddressesSuccess(res.data));
        done();
      })
      .catch(error => {
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message:
                getState()?.intl?.messages?.Errors?.Profile
                  ?.Get_Shipping_Addresses_Error
            }
          })
        );
        dispatch(getShippingAddressesError({ err: error }));
        done();
      });
  }
});

export const addUserShippingAddressLogic = createLogic({
  type: ADD_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
      const { address, actions } = action.payload;
      httpClient(dispatch)
        .post('/shipping-addresses', address)
        .then(res => {
          dispatch(addUserShippingAddressSuccess(res.data));
          actions?.setSubmitting?.(false);
          actions?.onClose?.();
          done();
        })
        .catch(error => {
          const intlErrors = getState()?.intl?.messages?.Errors;
          const errorMessage =
            intlErrors?.ShippingAddresses?.Confirm_Address_Error;

          dispatch(
            addUserShippingAddressError({
              message: errorMessage
            })
          );
          actions?.setSubmitting?.(false);
          done();
        });
     }
});

export const updateUserShippingAddressLogic = createLogic({
  type: UPDATE_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
      const { address, actions } = action.payload;
      dispatch(addAddressBeingProcessed(address.id));

      httpClient(dispatch)
        .put('/shipping-addresses', address)
        .then(res => {
          dispatch(
            updateUserShippingAddressSuccess({
              existingId: address.id,
              updatedShippingAddress: res.data
            })
          );
          dispatch(updateAutoshipsWithShippingAddress({
            existingId: address.id,
            address: res.data
          }));
          dispatch(removeAddressBeingProcessed(address.id));
          actions?.setSubmitting?.(false);
          actions?.onClose?.();
          done();
        })
        .catch(error => {
          const { status } = error.response;
          const intlErrors = getState()?.intl?.messages?.Errors;
          const errorMessage =
            status === 406
              ? intlErrors?.ShippingAddresses?.Confirm_Address_Error
              : intlErrors?.ShippingAddresses?.Update_Address_Error;

          // show error in modal vs in banner
          if (actions?.onClose) {
            dispatch(
              updateUserShippingAddressError({
                message: errorMessage
              })
            );
          } else {
            const bannerState = {
              data: {
                type: 'error',
                message: errorMessage
              }
            };
            dispatch(setBannerState(bannerState));
          }

          dispatch(removeAddressBeingProcessed(address.id));
          actions?.setSubmitting?.(false);
          done();
        });
  }
});

export const deleteUserShippingAddressPendingLogic = createLogic({
  type: DELETE_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const shippingAddressId = action?.payload;
    httpClient(dispatch)
      .delete(`/shipping-addresses/${shippingAddressId}`)
      .then(() => {
        dispatch(deleteUserShippingAddressSuccess(shippingAddressId));
          dispatch(removeAddressBeingProcessed(shippingAddressId));


        done();
      })
      .catch(error => {
          dispatch(removeAddressBeingProcessed(shippingAddressId));
          const errorMessage =
            getState()?.intl?.messages?.Errors.ShippingAddresses
              .Delete_Address_Error;
          const bannerState = {
            data: {
              type: 'error',
              message: errorMessage
            }
          };
          dispatch(setBannerState(bannerState));
        done();
      });
  }
});

export const deleteUserShippingAddressConfirmLogic = createLogic({
  type: DELETE_USER_SHIPPING_ADDRESS_CONFIRM,
  latest: true,
  processOptions: {
    dispatchMultiple: true
  },
  process({ action }, dispatch) {
      const address = action.payload;
      const modalProps = {
        show: true,
        message: `Are you sure you want to delete the following address:
                ${action.payload.line1}?`,
        okLabel: 'Yes',
        okDataHook: 'deleteAddressYes.button',
        cancelLabel: 'No',
        cancelDataHook: 'deleteAddressNo.button',
        buttonOrder: 'rtl',
        okClick: () => {
          dispatch(addAddressBeingProcessed(address.id));
          dispatch(deleteUserShippingAddressPending(address.id));
          dispatch(toggleModal());
        },
        cancelClick: () => {
          dispatch(toggleModal());
        }
      };
      dispatch(triggerModal(modalProps));

  }
});

export const updateAccountShippingAddressLogic = createLogic({
  type: UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { organizationId, billingAccountId, address, actions } =
      action.payload;
    dispatch(addAddressBeingProcessed(address.id));

    httpClient(dispatch)
      .put(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses`,
        address
      )
      .then(res => {
        dispatch(
          updateAccountShippingAddressSuccess({
            organizationId,
            billingAccountId,
            existingId: address.id,
            updatedShippingAddress: res.data
          })
        );

        dispatch(updateAutoshipsWithShippingAddress({address: res.data, existingId: address.id}));

        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        actions?.onClose?.();
        done();
      })
      .catch(error => {
        const { status } = error.response;
        const intlErrors = getState()?.intl?.messages?.Errors;
				let errorMessage = "";
        if(status === 404){
        	errorMessage = intlErrors?.ShippingAddresses?.Confirm_Address_Error
        } 
				else if(status === 406){
					errorMessage = intlErrors?.ShippingAddresses?.Missing_Address_Fields_Error;
				} else {
					errorMessage = intlErrors?.ShippingAddresses?.Update_Address_Error;
				}

        // show error in modal vs in banner
        if (actions?.onClose) {
          dispatch(
            updateAccountShippingAddressError({
              message: errorMessage
            })
          );
        } else {
          const bannerState = {
            data: {
              type: 'error',
              message: errorMessage
            }
          };
          dispatch(setBannerState(bannerState));
        }

        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        done();
      });
  }
});

export const addAccountShippingAddressLogic = createLogic({
  type: ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { address, actions, organizationId, billingAccountId } =
      action.payload;

    httpClient(dispatch)
      .post(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses`,
        address
      )
      .then(res => {
        dispatch(
          addAccountShippingAddressSuccess({
            organizationId,
            billingAccountId,
            address: res.data
          })
        );
        actions?.setSubmitting?.(false);
        actions?.onClose?.();
        done();
      })
      .catch(error => {
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage =
          intlErrors?.ShippingAddresses?.Confirm_Address_Error;

        dispatch(
          addAccountShippingAddressError({
            message: errorMessage
          })
        );
        actions?.setSubmitting?.(false);
        done();
      });
  }
});

export const deleteAccountShippingAddressConfirmLogic = createLogic({
  type: DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM,
  latest: true,
  processOptions: {
    dispatchMultiple: true
  },
  process({ action }, dispatch) {
    const { organizationId, billingAccountId, addressId, line1 } =
      action.payload;
    const payload = {
      organizationId,
      billingAccountId,
      addressId
    };
    const modalProps = {
      show: true,
      message: `Are you sure you want to delete the following address:
                ${line1}?`,
      okLabel: 'Yes',
      okDataHook: 'deleteAddressYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deleteAddressNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(addAddressBeingProcessed(addressId));
        dispatch(deleteAccountShippingAddressPending(payload));
        dispatch(toggleModal());
      },
      cancelClick: () => {
        dispatch(toggleModal());
      }
    };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  }
});

export const deleteAccountShippingAddressPendingLogic = createLogic({
  type: DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { organizationId, billingAccountId, addressId } = action.payload;
    const payload = {
      organizationId,
      billingAccountId,
      addressId
    };
    httpClient(dispatch)
      .delete(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses/${addressId}`
      )
      .then(() => {
        dispatch(deleteAccountShippingAddressSuccess(payload));
        dispatch(removeAddressBeingProcessed(addressId));
        done();
      })
      .catch(error => {
        dispatch(removeAddressBeingProcessed(addressId));
        const errorMessage =
          getState()?.intl?.messages?.Errors.ShippingAddresses
            .Delete_Address_Error;
        const bannerState = {
          data: {
            type: 'error',
            message: errorMessage
          }
        };
        dispatch(setBannerState(bannerState));
        done();
      });
  }
});

export default [
  getShippingAddressesLogic,
  addUserShippingAddressLogic,
  updateUserShippingAddressLogic,
  deleteUserShippingAddressConfirmLogic,
  deleteUserShippingAddressPendingLogic,
  updateAccountShippingAddressLogic,
  deleteAccountShippingAddressPendingLogic,
  deleteAccountShippingAddressConfirmLogic,
  addAccountShippingAddressLogic
];
