import { getOrgBillingAccounts } from '../../selectors/OrgManagementSelectors';
import { getCreditCards, getOrgBillingAccountPaymentMethods, getPaypalAccounts, getShippingAddresses } from '../../selectors/PersonProfileSelectors';


export const getCurrentUser = (state) => {
  return {
    firstName: state.app.getIn(['currentUser', 'firstName']),
    lastName: state.app.getIn(['currentUser', 'lastName']),
    email: state.app.getIn(['currentUser', 'emailAddress']),
  };
};



const getSavedPaymentMethods = (state, organizationId) => {
  const creditCards = getCreditCards(state);
  const paypalAccounts = getPaypalAccounts(state);

  const billingAccounts = organizationId != null
    ? getOrgBillingAccountPaymentMethods(state, organizationId)
    : [];

  return [
    ...creditCards.map(cc => ({ paymentMethod: cc, type: 'credit-card' })),
    ...paypalAccounts.map(pp => ({ paymentMethod: pp, type: 'paypal' })),
    ...billingAccounts.map(ba => ({ paymentMethod: ba, type: 'billing-account' }))
  ];
};

const getPaymentMethods = (state, organizationId) => {
  const creditCards = getCreditCards(state);
  const paypalAccounts = getPaypalAccounts(state);

  const billingAccounts = organizationId != null
    ? getOrgBillingAccounts(state, organizationId)
    : [];

  return [
    ...creditCards.map(cc => ({ paymentMethod: cc, type: 'credit-card' })),
    ...paypalAccounts.map(pp => ({ paymentMethod: pp, type: 'paypal' })),
    ...billingAccounts.map(ba => ({ paymentMethod: ba, type: 'billing-account' }))
  ];
};

export const getMatchingPaymentMethod = (state, { paymentMethodId, organizationId }) => {
  const savedPaymentMethods = getSavedPaymentMethods(state, organizationId);
  const paymentMethod = savedPaymentMethods.find(pm => pm.paymentMethod.id === paymentMethodId);

  if (paymentMethod == null) {
    throw new Error(`the user does not own payment method ${paymentMethodId}`);
  }

  return paymentMethod;
};

export const getPaymentMethod = (state, { paymentMethodId, organizationId }) => {
  const savedPaymentMethods = getPaymentMethods(state, organizationId);
  const paymentMethod = savedPaymentMethods.find(pm => pm.paymentMethod.id === paymentMethodId);

  if (paymentMethod == null) {
    throw new Error(`the user does not own payment method ${paymentMethodId}`);
  }

  return paymentMethod;
};




export const formatAutoshipPaymentMethod = (paymentMethodType, paymentMethod) => {
  if (paymentMethodType === 'billing-account') {
    return {
      id: paymentMethod.id,
      account: paymentMethod.accountNumber,
      organizationId: paymentMethod.organizationId,
      billingAccountId: paymentMethod.billingAccountId,
      name: paymentMethod.organizationName,
    };
  } else if (paymentMethodType === 'credit-card') {
    return {
      card: {
        id: paymentMethod.id,
        token: paymentMethod.token,
        cardholderName: paymentMethod.cardHolderName,
        cardType: paymentMethod.cardType,
        cardMask: paymentMethod.cardNumberMask,
        expMonth: paymentMethod.expirationMonth,
        expYear: paymentMethod.expirationYear,
        displayName: paymentMethod.displayName,
      }
    };
  } else if (paymentMethodType === 'paypal') {
    return {
      paypal: {
        id: paymentMethod.id,
        token: paymentMethod.token,
        emailAddress: paymentMethod.email,
        payerId: paymentMethod.payerId,
        displayName: paymentMethod.displayName,
      }
    };
  } else {
    throw new Error(`unsupported payment type of ${paymentMethodType}`);
  }
};



export const getMatchingAddress = (state, organizationId, addressId) => {
  const userAddresses = getShippingAddresses(state);
  const orgAddresses = getOrgBillingAccounts(state, organizationId)
    ?.map(ba => ba.addresses)
    ?.flat() ?? [];

  const matchingAddress = [...userAddresses, ...orgAddresses]
    .find(address => address.id === addressId);

  if (matchingAddress == null) {
    throw {
      errorSource: 'no-matching-address',
      error: new Error(`address ${addressId} could not be found`)
    };
  }

  return matchingAddress;
};



export const formatAutoshipAddress = (address, user) => {
  return {
    firstName: address.firstName || user.firstName,
    lastName: address.lastName || user.lastName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.countrySubdivision,
    zip: address.postalCode,
    country: address.countryCode,
    addressType: address.type,
    phone: address.phoneNumber,
    id: address.id,
    organization: address.organizationName,
  };
};

export const formatContact = (contact) => {
  return {
    userId: contact?.personId || contact?.userId || contact?.customerId,
    email: contact.email,
    firstName: contact.firstName,
    lastName: contact.lastName
  };
};

