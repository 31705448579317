import React, { createContext, useState, useEffect, useContext } from 'react';
import { bool, node, number } from 'prop-types';

const viewportContext = createContext({});

export const ViewportTestProvider = ({ children, height, width, isMobile }) => (
  <viewportContext.Provider value={{ height, width, isMobile }}>
    {children}
  </viewportContext.Provider>
);
ViewportTestProvider.propTypes = {
  children: node,
  height: number,
  width: number,
  isMobile: bool
};

export const ViewportProvider = ({ children }) => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(checkMobileWidth());
  const [isTablet, setIsTablet] = useState(checkTabletWidth());
  const [isLgTablet, setIsLgTablet] = useState(checkLgTabletWidth());

  const handleWindowResize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    setIsMobile(checkMobileWidth());
    setIsTablet(checkTabletWidth());
    setIsLgTablet(checkLgTabletWidth());
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ height, width, isMobile, isTablet, isLgTablet }}>
      {children}
    </viewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: node,
};

const maxMobileWidth = 721;
const maxTabletWidth = 1024;
const maxLgTabletWidth = 1230;

const checkMobileWidth = () => window.innerWidth < maxMobileWidth;
const checkTabletWidth = () => window.innerWidth > maxMobileWidth && window.innerWidth <= maxTabletWidth;
const checkLgTabletWidth = () => window.innerWidth > maxTabletWidth && window.innerWidth < maxLgTabletWidth;

export const useViewport = () => useContext(viewportContext);
