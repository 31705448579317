import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import css from '../../../css/css-variables';
import dismissIcon from '../../../img/sprite/x-icon.svg';

const Container = styled.div`
  border: 1px solid;
  margin: 10px 0;
  padding: ${props => props.transfer ? '0.75rem 1rem' : '15px'};
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  ${props => props.colors}
  color: ${props => props.transfer ? '#70181E' : ''};
`;

const DismissButton = styled.button`
  float: right;
  border: 0 none;
  background-color: transparent;
  height: 26px;

  display: flex;

  margin-right: -0.5em;
  padding: .5em;
  border-radius: 50%;
  transition: all .25s ease;

  &:hover {
    background-color: rgba(34, 34, 34, .15);
  }

  & svg {
    width: 10px;
    height: 10px;
  }
`;
DismissButton.displayName = 'DismissButton';

const MessageText = styled.div`
  padding: 0;
  margin: 0;
  font-size: ${props => props.transfer ? '.875rem' : 'inherit'};
`;
MessageText.displayName = 'MessageText';

const MessageLink = styled.a`
  text-decoration: underline;
`;

const MessageBanner = forwardRef(({ transfer, link, linkText, error, message, children, type = 'message', className, clearBanner }, ref) => {
  const hideBanner = () => clearBanner();
  const messageType = error ? 'error' : type;

  let colors;

  switch (messageType) {
    case 'success':
      colors = `
        color: #55884a;
        background-color: #d9eece;
        border-color: #bed0b4;
      `;
      break;
    case 'blue':
      colors = `
        color: #264888;
        background-color: #D9EDF7;
        border-color: #FFFFFF;
        `;
        break;
    case 'error':
      colors = `
        color: #b54a42;
        background-color: #f3dddf;
        border-color: #ebccd1;
      `;
      break;

    case 'warning':
      colors = `
        color: #7A7070;
        background-color: ${css.colors.Warning};
        border-color: ${css.colors.Warning};
      `;
      break;
    case 'plain':
      colors = `
        border-color: #e0e0e0;
        background-color: #fbfbfb;
        color: #66757f;
      `;
      break;
    default:
      colors = `
        color: #5690da;
        background-color: #daecf8;
        border-color: #bbdef2;
      `;
  }

  return (
    <React.Fragment>
      {(message || children) &&
        <Container ref={ref} id="messageBanner" colors={colors} className={className} transfer={transfer}>
          <MessageText transfer={transfer} data-qa-hook="banner.txt">
            {message}
            {link && <MessageLink href={link}>{linkText}</MessageLink>}
            {children}
          </MessageText>
          {clearBanner &&
            <DismissButton onClick={hideBanner} aria-label="Dismiss Banner" type="button">
              <svg role="img"><use href={`#${dismissIcon.id}`} /></svg>
            </DismissButton>
          }
        </Container>
      }
    </React.Fragment>
  );
});

MessageBanner.displayName = 'MessageBanner';

const { bool, string, node, func } = PropTypes;

MessageBanner.propTypes = {
  error: bool,
  message: node,
  children: node,
  type: string,
  className: string,
  clearBanner: func,
  link: string,
  linkText: string,
};

export default MessageBanner;
