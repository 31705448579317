import React, { useEffect, useState } from 'react';
import { ORG_PATHS } from '../constants';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { orgMenuLinks } from './header/account-menu/menuLinks';
import DownChevron from '../../../img/sprite/down-chevron.svg';
import { RedeemButton } from './RedeemButton';
import { ButtonContainer } from './SideNavSelect';
import { connect, useSelector } from 'react-redux';
import { getViewTransactionHistoryAccess } from '../selectors/OrgManagementSelectors';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import OrderHistoryDropdown from './OrderHistoryDropdown';
import BillingAccountsDropdown from './BillingAccountsDropdown';
import { injectParams } from '../utils/pathHelpers';

const StyledLink = styled(Link)`
  border: none;
  font-size: 16px;
  color: #476692;
  text-decoration: none;
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  padding: 8px 0;

  &:hover {
    text-decoration: underline;
  }
`;
export const AccountLink = styled(Link)`
  border: none;
  font-size: 16px;
  color: #476692;
  text-decoration: none;
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  padding: 8px 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const GroupTitle = styled.div`
  border-top: ${({title}) => title === 'My Settings' || title === 'Settings' ? '1px solid #e3e3e3' : 'none'};
  font-size: 11px;
  color: #999999; 
  text-decoration: none;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  padding: ${({isLastChild}) => isLastChild ? '14px 0 8px 0' : '8px 0'};
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function SideNavOrg({
  selectedOrg,
  updatedOrgMenuLinks,
  pathname,
  showBackBtn,
  view,
  setView,
  organizationId,
}) {

  const billingAccounts = selectedOrg?.organizationProfile?.billingAccounts;
  const firstAccount = selectedOrg?.organizationProfile?.billingAccounts?.[0]?.id;
  const [showBillingAccounts, setShowBillingAccounts] = useState(false);
  const [showOrderAccounts, setShowOrderAccounts] = useState(false);
  const [activeBillingAccount, setActiveBillingAccount] = useState(null);

  useEffect(() => {
    // Split the URL by '/' to get an array of segments
    const segments = pathname.split('/');

    // Find the index of 'billing-activity' in the array
    const baIndex = pathname.includes('billing-activity') ? segments.indexOf('billing-activity') : segments.indexOf('order-history')

    if (baIndex !== -1 && baIndex < segments.length - 1) {
      // Extract the parameter value that follows 'billing-activity'
      const param = segments[baIndex + 1];
      setActiveBillingAccount(param);
    }
  }, [pathname]);

  //Show or hide billing account and order history dropdown components
  useEffect(() => {
    if (pathname.includes('billing')) {
      setShowBillingAccounts(true);
      setShowOrderAccounts(false);
    } else if (pathname.includes('order-history')) {
      setShowOrderAccounts(true);
      setShowBillingAccounts(false);
    } else {
      setShowBillingAccounts(false);
      setShowOrderAccounts(false);
    }
  }, [pathname]);

  //Filter billingAccounts that are active
  const filteredActiveAccounts = billingAccounts?.filter(account => account.active === true);

  const defaultPath = ORG_PATHS.DASHBOARD.replace(':organizationId', selectedOrg?.organizationId);

  const handleTransactionHistoryAccess = useSelector(
    state => getViewTransactionHistoryAccess(
      state, selectedOrg?.organizationId, filteredActiveAccounts?.map(account => account.accountNumber)
    )
  );

  const accountsWithPermission = handleTransactionHistoryAccess.filter(permission => permission.hasAccess === true).map(permission => permission.permissionScope.account);

  const activeAccountsWithPermission = filteredActiveAccounts?.filter(
    account => accountsWithPermission.includes(account.accountNumber)
  );

  const hasTransactionHistoryAccess = handleTransactionHistoryAccess.some(permission => permission.hasAccess === true);

  //Set activeBillingAccount to firstAccount to prevent url from showing undefined
  useEffect(() => {
    if (activeAccountsWithPermission) {
      setActiveBillingAccount(activeAccountsWithPermission?.[0]?.id)
    }
  }, [activeAccountsWithPermission, firstAccount]);


  const hasBillingAccount = activeAccountsWithPermission?.length === 1;
  const hasMultipleAccounts = activeAccountsWithPermission?.length > 1;

  const handleBillingAccountId = () => {
    if(hasBillingAccount) {
      return activeAccountsWithPermission?.[0].id
    } else if (hasMultipleAccounts) {
      return activeBillingAccount
    } else {
      return null
    }
  };

  const handleOrgUrl = (url) => {
    if (url?.includes('order')) {
      return injectParams(url, { organizationId: selectedOrg?.organizationId, billingAccountId: handleBillingAccountId() })
    } else if (url?.includes('billing')) {
      return injectParams(url, { organizationId: selectedOrg?.organizationId, billingAccountId: handleBillingAccountId() })
    } else {
      return url.replace(':organizationId', selectedOrg?.organizationId)
    }
  };

  const formatLinkName = (linkName) => {
    switch (linkName) {
      case 'Order History':
        return 'order-history';
      case 'Subscriptions':
        return 'subscriptions';
      case 'Autoship':
        return 'autoship';
      case 'Billing':
        return 'billing-activity';
      case 'Organization Profile':
        return 'profile';
      case 'Addresses':
        return 'shipping-addresses';
      case 'Groups (Staff)':
        return 'groups';
    }
  };

  const LinkName = ({ name }) => {
    const baActive = pathname.includes('billing-activity');
    const orderHistoryActive = pathname.includes('order-history');

    return (
      <LinkContainer>
        {name}
        {name === 'Billing' && hasMultipleAccounts &&
          <svg style={{ transform: baActive ? 'none' : 'rotate(270deg)' }}>
            <use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} />
          </svg>
        }
        {name === 'Order History' && hasMultipleAccounts &&
          <svg style={{ transform: orderHistoryActive ? 'none' : 'rotate(270deg)' }}>
            <use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} />
          </svg>
        }
      </LinkContainer>
    )
  };

  return (
    <>
      {showBackBtn && view !== 'SELECT' && (
        <div style={{ width: '100%', borderBottom: 'solid 1px #e0e0e0', padding: '16px 0', alignItems: 'center', cursor: 'pointer' }} onClick={() => setView('SELECT')}>
          <svg style={{ transform: 'rotate(90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
          <button type="button" style={{ fontWeight: 'bold', background: 'white', border: 'none', fontSize: '14px', color: '#476692', textDecoration: 'none' }}>
            Switch Account
          </button>
        </div>
      )}
      <div>
        <div style={{ padding: '16px 0 7px' }}>
          <StyledLink bold={true} to={defaultPath}>
            {selectedOrg?.organizationName}
          </StyledLink>
        </div>
        <div style={{padding: '0 0 7px 0'}}>
          <StyledLink to={ORG_PATHS.DASHBOARD.replace(
            ':organizationId',
            selectedOrg?.organizationId
          )}
            style={{
            fontSize: '14px',
            color: pathname === defaultPath ? '#414042' : '#476692',
            fontWeight: pathname === defaultPath ? 'bold' : 'normal'
          }}
          >
            Account Home
          </StyledLink>
        </div>
        {(!hasTransactionHistoryAccess ? updatedOrgMenuLinks : orgMenuLinks)?.linkGroup.map((group, index) => {
          const isLastChild = (hasTransactionHistoryAccess ? updatedOrgMenuLinks : orgMenuLinks).linkGroup.length -1 === index;
          return (
          <>
            <GroupTitle key={index} title={group?.title} isLastChild={isLastChild}>{group?.title}</GroupTitle>
            {group?.links.map((link, index) => {
              const isLastChild = group?.links.length -1 === index;
              const isActiveLink = pathname.includes(formatLinkName(link?.name)) && pathname.includes(selectedOrg?.organizationId);
              return (
              <div style={isLastChild ? { padding : '0 0 14px 0'} : { padding: '0 0 8px 0'}}>
                <StyledLink
                  key={index}
                  to={handleOrgUrl(link?.url)}
                  style={{
                    fontSize: '14px',
                    color: (isActiveLink) ? '#414042' : '#476692',
                    fontWeight: (isActiveLink) ? 'bold' : 'normal'
                  }}
                >
                  <LinkName name={link?.name} />
                </StyledLink>
                {link?.name.includes('Billing') && hasMultipleAccounts && showBillingAccounts &&
                  activeAccountsWithPermission.map(account => {
                    return (
                    <BillingAccountsDropdown
                      key={account.id}
                      billingAccount={account}
                      pathname={pathname}
                      organizationId={organizationId}
                      setActiveBillingAccount={setActiveBillingAccount}
                    />
                  )})                
                }
                {link?.name.includes('Order') && hasMultipleAccounts && showOrderAccounts &&
                  activeAccountsWithPermission.map(account => {
                    return (
                      <OrderHistoryDropdown
                        key={account.id}
                        billingAccount={account}
                        pathname={pathname}
                        organizationId={organizationId}
                        setActiveBillingAccount={setActiveBillingAccount}
                      />
                    )
                  })
                }
              </div>
            )})}
          </>
        )})}
        <ButtonContainer>
          <RedeemButton isSideNav={true} style={{ fontSize: '14px', width: '100%' }} />
        </ButtonContainer>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const route = state.router.location.pathname;
  const orgRouteMatch = matchPath(route, { path: ORG_PATHS.BASE });
  const organizationId = orgRouteMatch?.params?.organizationId;

  return {
    organizationId
  };
}

const { string, object, bool, func } = PropTypes;

SideNavOrg.propTypes = {
  selectedOrg: object,
  updatedOrgMenuLinks: object,
  pathname: string,
  showBackBtn: bool,
  view: string,
  setView: func,
  organizationId: string
};

export default connect(mapStateToProps)(SideNavOrg);
