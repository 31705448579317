import styled from 'styled-components';
import { Link } from 'react-router-dom';
import cssVars from '../../../css/css-variables';

export const OutlineButton = styled(Link)`
  display: flex;
  padding: var(--numbers-num-xs, 0.5rem) var(--numbers-num-sm, 0.75rem);
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.875rem;
  border: 2px solid var(--button-primary-border, #414042);
  background: var(--button-secondary-background, #fff);
  color: var(--button-secondary-foreground, #414042);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration: none;

  @media screen and (min-width: 1024px) {
    ${({ showOnTablet }) => showOnTablet && 'display: none' };
  }

  @media screen and (max-width: 720px) {
    margin: 12px 0;
  }

  @media screen and (max-width: 350px) {
    padding: var(--numbers-num-xs, 0.3rem) var(--numbers-num-sm, 0.6rem);
    font-size: 0.775rem;
  }

  :hover {
    outline: 1px solid black;
  }

  :active {
    outline: 1px solid ${cssVars.colors.RedeemActive};
  }
`;
