import React from "react";
import styled from "styled-components";
import CreditCardIcon from "../../../../img/sprite/credit-card.svg";
import ChurchIcon from "../../../../img/sprite/church.svg";
import css from "../../../../css/css-variables";
import { PaymentTypes } from "./SelectPaymentMethodModal";

const SelectPaymentMethodHeader = ({ selectedPaymentType, handleOnClick, isOrgSubs, isIndividualSub }) => {

  return (
    <>
      <PaymentTypeCard
        onClick={() => handleOnClick(PaymentTypes.CREDITCARD)}
        selected={
          selectedPaymentType === PaymentTypes.CREDITCARD ? true : false
        }
      >
        <PaymentTypeSpan
          height="22px"
          width="27px"
          selected={
            selectedPaymentType === PaymentTypes.CREDITCARD ? true : false
          }
        >
          <PaymentTypeIcon
            selected={
              selectedPaymentType === PaymentTypes.CREDITCARD ? true : false
            }
          >
            <use href={`#${CreditCardIcon.id}`} />
          </PaymentTypeIcon>
        </PaymentTypeSpan>
        <PaymentTypeText>Card</PaymentTypeText>
      </PaymentTypeCard>

        <PaymentTypeCard
          onClick={() => handleOnClick(PaymentTypes.BILLINGACCOUNT)}
          selected={
            selectedPaymentType === PaymentTypes.BILLINGACCOUNT ? true : false
          }
        >
          <PaymentTypeSpan
            height="26px"
            width="32px"
            selected={
              selectedPaymentType === PaymentTypes.BILLINGACCOUNT ? true : false
            }
          >
            <PaymentTypeIcon
              selected={
                selectedPaymentType === PaymentTypes.BILLINGACCOUNT
                  ? true
                  : false
              }
            >
              <use href={`#${ChurchIcon.id}`} />
            </PaymentTypeIcon>
          </PaymentTypeSpan>
          <PaymentTypeText>Billing Account</PaymentTypeText>
        </PaymentTypeCard>
    </>
  );
};

const PaymentTypeCard = styled.button`
	display: flex;
	align-items: center;
	height: 36px;
	width: fit-content;
	padding: 0;
	background-color: transparent;
	border: none;

	${(props) =>
    props.selected &&
    `
		p{
			color: ${css.colors.DarkGray};
		}
  `}

	:hover{
		svg{
			color:  ${css.colors.DarkGray};
		}
		span{
			border-color:  ${css.colors.LifewayYellow};
		}
		p{
			color:  ${css.colors.DarkGray};
		}
	}

	}
`;

const PaymentTypeSpan = styled.span`
  width: 49px;
  height: 36px;
  border: 3px solid ${css.colors.DarkBorderHover};
  border-radius: 5px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  stroke-width: 3;

  ${(props) =>
    props.selected
      ? `
		border-color: ${css.colors.LifewayYellow};
        svg{
            color: ${css.colors.DarkGray};
        }
  `
      : `svg{
		color: ${css.colors.DarkBorderHover};
	}`}

  svg {
    ${({ height }) => height && `height: ${height};`}
    ${({ width }) => width && `width: ${width};`}
  }
`;
const PaymentTypeIcon = styled.svg``;

const PaymentTypeText = styled.p`
  font-size: 12px;
  color: ${css.colors.DarkBorderHover};
  text-decoration: none solid rgb(65, 64, 66);
  line-height: 16px;
  padding: 0 20px 0 5px;
  text-transform: capitalize;
`;

export default SelectPaymentMethodHeader;
